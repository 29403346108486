import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "./../../assets/images/logo.png";
import PROFILE_IMAGE from "./../../assets/images/default.svg";
import { AiOutlineLogout } from "react-icons/ai";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import SEARCH_ICON from "./../../assets/images/Search.svg";
import ENGLISH from "../../assets/english.png";
import RUSSIAN from "../../assets/russia.png";
import BURGER from "../../assets/images/hambergermenu.svg";
import { Translator, Translate } from "react-auto-translate";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationsTostify } from "../../lib/Redux/slices/userslice";

function Header() {
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sidebar, setSideBar] = useState(false);
  const [dropdown, setDropDown] = useState(false);
  const [dlang, setDLang] = useState("ru");

  useEffect(() => {
    // user_profile();
    const collapseValue = localStorage.getItem("i18nextLng");
    if (collapseValue === null) {
      setDLang("ru");
    } else {
      setDLang(collapseValue);
    }
  }, [dlang]);

  const user_profile = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "get_user_profile");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.data);
      }
    }
  };

  const do_logout = async () => {
    localStorage.removeItem("AIWORDAUTH");
    setLoggedObject(null);
    window.location.href = "/";
  };

  const do_make_action = () => {
    document.body.classList.add("mobile_view_p");
  };

  const do_update_language = (val) => {
    localStorage.setItem("i18nextLng", val);
    setDLang(val);
    window.location.reload();
  };

  const dispatch = useDispatch();

  const GetToastNotifications = async () => {
    const payload = {
      slug: 1,
      lang: dlang,
    };
    const { isError, data } = await doPost(payload, "auth/sessionNotifiations");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
    } else {
      if (data.status) {
        dispatch(setNotificationsTostify(data?.data));
      } else {
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    GetToastNotifications();
  }, [dlang]);

  const { NotificationsToasts = null } = useSelector((state) => state.user);

  return (
    <>
      <div className="">
        <nav className="header-nav navbar navbar-expand-lg navbar-light">
          <span className="navbar-brand">
            <Link className="logo" to="/dashboard">
              <img src={LOGO} />
            </Link>
          </span>
          <button
            aria-controls="basic-navbar-nav"
            type="button"
            aria-label="Toggle navigation"
            className="navbar-toggler collapsed"
            onClick={() => setSideBar(!sidebar)}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`justify-content-end navbar-collapse collapse wd100 ${
              sidebar ? "show" : ""
            }`}
            style={{
              alignItems: "center",
              flexGrow: "0",
              justifyContent: "flex-end !important",
              width: "100%;",
            }}
            id="basic-navbar-nav"
          >
            {/* <div className="center-nav navbar-nav" ></div> */}
            <div className="withlogin_out">
              <div
                className="language_switcher"
                onClick={() => setDropDown(!dropdown)}
              >
                <img src={dlang == "en" ? ENGLISH : RUSSIAN} />{" "}
                {dlang == "en" ? "English" : "Russian"}
                {dropdown && (
                  <div class="switcher_dropdown">
                    <div className="" onClick={() => do_update_language("en")}>
                      <img src={ENGLISH} />
                      <span>english</span>
                    </div>
                    <div className="" onClick={() => do_update_language("ru")}>
                      <img src={RUSSIAN} />
                      <span>Russian</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="myprofile-link">
              <Link
                variant="primary"
                className="head-login-btn btn-primary"
                to="/login"
              >
                <Translate>Login</Translate>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
export default Header;
