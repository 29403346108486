import React, { useEffect, useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Translate } from "react-auto-translate";
import { useSelector } from "react-redux";

function Jobs() {
  const { getLoggedObject } = useAuth();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const [mode, setMode] = useState(1);
  const [plans, setPlans] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [PlansTab, setPlansTab] = useState("Monthly");
  const [yearlySave, setyearlySave] = useState("");

  const do_show_tab = async (val) => {
    if (val == tab) {
      setTab(0);
    } else {
      setTab(val);
    }
  };

  useEffect(() => {
    get_pricing_info();
  }, []);

  const lang_outer = localStorage.getItem("i18nextLng");
  const lang_cond = lang_outer == "en" ? "en" : "ru";

  const get_pricing_info = async () => {
    const lang = localStorage.getItem("i18nextLng");
    const payload = {
      token: getLoggedObject()?.token,
      v: 1,
      lang: lang,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/get_all_plans_portal"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setPlans(data?.data);
        setyearlySave(data?.yearly_percentage);
        setFaqs(data?.faqs);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  // const purchasePacakge = async (item) => {
  //     var body_data = {amount: item.price, planId: item.id, token:getLoggedObject().token};
  //     const {isError, data}  = await doPost(body_data, 'auth/upgrade_plan');
  //     if(isError) {
  //         toast.error('Something went wrong with server.');
  //         setLoading(false);
  //     }else{
  //         if(data.action == "success"){
  //             get_pricing_info();
  //             const {client_secret, publishableKey} = data?.data;
  //             navigate('/payment', {state: {plan: {...item, client_secret, publishableKey}}})
  //         } else {
  //             setLoading(false);
  //             toast.error(data?.data);
  //         }
  //     }
  // }

  const purchasePacakge = async (item) => {
    var body_data = {
      amount: Number(item.price),
      planId: Number(item.id),
      token: getLoggedObject().token,
    };
    setLoading(true);
    const { isError, data } = await doPost(body_data, "auth/paymentYooMoney");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data?.action === true) {
        setLoading(false);
        let paymentId = localStorage.setItem("PaymentId", data?.data?.id);
        let PLANID = localStorage.setItem("plan_id", data?.data?.plan_id);
        window.location.href = data?.data?.confirmation_url;
      } else {
        setLoading(false);
        toast.error(data?.data?.message);
      }
    }
  };

  const handleTabClick = (tabKey) => {
    setPlansTab(tabKey);
  };

  return (
    <>
      <div className="container-wrappe">
        {" "}
        <div className="inline-pricing inPage">
          <div className="header-txt sub-head text-center">
            <div className="">
              <h2 style={{ textAlign: "center" }}>
                <Translate> Plan</Translate>
              </h2>
              <p>
                {lang_cond == "en" ? (
                  <div>
                    Choose from available plans to use the main tools and
                    functions of our platform.
                  </div>
                ) : (
                  <div>
                    Выберите один из доступных планов чтобы пользоваться
                    основными инструментами и функциями нашей платформы.
                  </div>
                )}
              </p>
            </div>
            <ul
              style={{ overflow: "hidden" }}
              className="mb-3 pricingTabs nav nav-tabs"
              role="tablist"
            >
              <li className="nav-item plansBtn" role="presentation">
                <button
                  type="button"
                  id="controlled-tab-example-tab-monthly"
                  role="tab"
                  className={`nav-link  ${
                    PlansTab === "Monthly" ? "plansActive" : ""
                  }`}
                  onClick={() => handleTabClick("Monthly")}
                >
                  <Translate>Monthly</Translate>
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  type="button"
                  id="controlled-tab-example-tab-yearly"
                  role="tab"
                  className={`nav-link ${
                    PlansTab === "Yearly" ? "plansActive" : ""
                  }`}
                  onClick={() => handleTabClick("Yearly")}
                >
                  <Translate>Yearly</Translate> ({yearlySave}%{" "}
                  <Translate>off</Translate>)
                </button>
              </li>
            </ul>
          </div>
          <div className="container">
            <div className="tab-content">
              <div
                role="tabpanel"
                id="controlled-tab-example-tabpane-monthly"
                aria-labelledby="controlled-tab-example-tab-monthly"
                className="fade tab-pane active show"
              >
                <div className="loaderInline" />
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  {plans
                    .filter((plan) => plan?.duration === PlansTab)
                    .map((v, index) => (
                      <div
                        key={index}
                        className={`col ${index === 3 ? "col-md-auto" : ""}`}
                      >
                        <div
                          style={{ width: "100%" }}
                          className="card pricing-box"
                        >
                          <div className="card-body">
                            <h4 className="card-title">
                              <Translate>Pro</Translate>
                            </h4>
                            <h3 className="unlimited-words">
                              <Translate>{v.title}</Translate>
                            </h3>
                            <p className="caption">
                              <Translate>
                                Generate {v.words == -1 ? "UNLIMITED" : v.words}{" "}
                                words per month
                              </Translate>
                            </p>
                            <div className="mb-4">
                              {v?.save_price !== "" && (
                                <span className="save">
                                  <span>
                                    <Translate>You Save:</Translate>
                                  </span>{" "}
                                  ₽{v?.save_price}
                                </span>
                              )}
                            </div>
                            <h5 className="card-subtitle mb-1">
                              ₽{v.price}
                              <span className="ml-1">
                                <Translate>/mo</Translate>
                              </span>
                              {PlansTab == "Yearly" && (
                                <>
                                  &nbsp;&nbsp;
                                  <span className="price_yearl">
                                    ₽{v.yearly_price}
                                    <Translate>/yr</Translate>
                                  </span>
                                </>
                              )}
                            </h5>

                            <div className="flex mt-4">
                              <button
                                type="button"
                                className=" btn btn-primary btn-md"
                                disabled={loading || v.id === v.plan}
                                onClick={() => purchasePacakge(v)}
                              >
                                <Translate>
                                  {loading
                                    ? "Upgrading..."
                                    : v.id === v.plan
                                    ? "CURRENT PLAN"
                                    : "Upgrade Now"}
                                </Translate>
                              </button>
                              <span className="text-mute">
                                <Translate>Cancel anytime</Translate>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="row planFeatures">
                            <div className="col-md-12">
                              <div className="card-text planIncludes">
                                <ul>
                                  {(v?.bullets).map((vv, ii) => (
                                    <li key={ii}>
                                      <i className="tickMark">✔</i> {vv}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="row mt-5 faqs">
              <div className="col-md-12">
                <div className="my-4 text-center">
                  <h3>
                    <Translate>FAQ's</Translate>
                  </h3>
                  <div>
                    <Translate>Frequently asked questions, answered.</Translate>
                  </div>
                </div>
                <div className="accordion accordion-flush">
                  {faqs.map((v, index) => (
                    <>
                      <div className="rounded mb-3 accordion-item">
                        <h2 className="accordion-header">
                          <button
                            type="button"
                            aria-expanded="true"
                            className={`accordion-button ${
                              tab == index + 1 ? "collapsed" : ""
                            } `}
                            onClick={() => do_show_tab(index + 1)}
                          >
                            {v.title}
                          </button>
                        </h2>
                        {tab == index + 1 && (
                          <div className={`accordion-collapse show`}>
                            <div
                              className="accordion-body"
                              dangerouslySetInnerHTML={{ __html: v.en }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
