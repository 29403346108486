import React, { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { toast } from "react-toastify";
import { Translate } from "react-auto-translate";
import { useSelector } from "react-redux";

function Jobs() {
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const [opass, setOPass] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const do_update = async () => {
    if (opass == "") {
      toast.error(NotificationsToasts?.enter_old_password?.notification);
      return;
    }
    if (password == "") {
      toast.error(NotificationsToasts?.enter_password?.notification);
      return;
    }
    if (cpassword == "") {
      toast.error(NotificationsToasts?.enter_confirm_password?.notification);
      return;
    }
    if (cpassword !== password) {
      toast.error(
        NotificationsToasts?.pass_confirm_password_not_matching?.notification
      );
      return;
    }
    const payload = {
      token: getLoggedObject()?.token,
      current: opass,
      newpass: password,
      cnewpass: cpassword,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/update_password");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setOPass("");
        setPassword("");
        setCPassword("");
        toast.success(
          NotificationsToasts?.password_updated_successfully?.notification
        );
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <>
      <div className="container-wrappe">
        {" "}
        <div className="main-layout profile page-layout">
          <div className="header-txt text-center">
            <h2>
              <Translate>Change Password</Translate>{" "}
            </h2>
            <p>
              <Translate>To change your password.</Translate>{" "}
            </p>
          </div>
          <div className="container justify-content-center">
            <div className="row">
              <div className="col-sm-12">
                <div className="mb-5 card">
                  <div className="card-body">
                    <div className="wrapper bg-white">
                      <div className="py-4">
                        <div className="justify-content-md-center mb-3 row">
                          <div className="col-md-12">
                            <div>
                              <label className="form-label" htmlFor="firstName">
                                <Translate>Old Password</Translate>
                              </label>
                              <input
                                placeholder=""
                                required=""
                                type="password"
                                id="opass"
                                className="form-control"
                                value={opass}
                                onChange={(e) => setOPass(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>
                              <label className="form-label" htmlFor="firstName">
                                <Translate>New Password</Translate>
                              </label>
                              <input
                                placeholder=""
                                required=""
                                type="password"
                                id="firstName"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>
                              <label className="form-label" htmlFor="lastName">
                                <Translate>Confirm Password</Translate>
                              </label>
                              <input
                                placeholder=""
                                type="password"
                                id="lastName"
                                className="form-control"
                                value={cpassword}
                                onChange={(e) => setCPassword(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" row">
                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="justify-content-md-center mt-3 btn btn-primary"
                              onClick={() => do_update()}
                            >
                              <Translate>Update</Translate>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: 16,
            pointerEvents: "none",
          }}
        />
      </div>
    </>
  );
}

export default Jobs;
