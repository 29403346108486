import React, { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { OPENAPI } from "./../../utils/apiCalls";
import { Translate } from "react-auto-translate";
import Common from "./common";
import ResponseDiv from "./Response";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Inner({ data = [] }) {
  const { state } = useLocation();
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const [blogimage, setBlogImage] = useState(state?.v.image);
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [brandname, setBrandName] = useState(data?.data[0]?.textfield || "");

  const [language, setLanguage] = useState("Russian");
  const [variant, setVariant] = useState(1);
  const [tone, setTone] = useState("Formal");
  const [creativity, setCreativity] = useState("0.75");
  const [responseAI, setResponseAI] = useState([]);
  const [type, setType] = useState("website_sub-headlines");

  const handleDropdownChange = (selectedValue) => {
    setLanguage(selectedValue);
  };
  const handleDropdownVariant = (selectedValue) => {
    setVariant(selectedValue);
  };
  const handleDropdownTone = (selectedValue) => {
    setTone(selectedValue);
  };
  const handleDropdownCreativity = (selectedValue) => {
    setCreativity(selectedValue);
  };

  const do_loop_variants = async () => {
    setLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = creativity;
    const content_submit =
      "Write a Website sub-headlines 6 points where Website Headline / Product Name is (" +
      brandname +
      ")";
    const payload = [
      { role: "user", content: content_submit },
      { role: "system", content: "Response should be in " + language },
      { role: "system", content: `You should use a ${tone} tone.` },
    ];
    // console.log(payload);
    // return;
    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );
    setLoading(false);
    if (response_ai == "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting?.notification
      );
      return;
    }
    if (response_ai == "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }
    const final_response = response_ai.choices[0].message.content;
    var lines = [final_response];
    setResponseAI((prevResponse) => [...prevResponse, ...lines]);
  };

  const do_make_action = async () => {
    if (brandname == "") {
      toast.error(
        NotificationsToasts?.enter_headline_product_name?.notification
      );
      return;
    }
    var user_variants = variant;
    for (let i = 0; i < user_variants; i++) {
      do_loop_variants();
    }
  };

  // const do_loop_variants = async () => {
  //     setLoading(true)
  //     const user_token = getLoggedObject()?.token;
  //     const max_tokens = 10;
  //     const temperature = creativity;
  //     const content_submit = 'Write a Website sub-headlines 6 points where Website Headline / Product Name is ('+brandname+')';
  //     // if(variant > 1){
  //     //     var append_c = 'and After each 6 points there will be a seperator ## and then next variant data should come';
  //     // } else {
  //     //     var append_c = '';
  //     // }
  //     const payload = [
  //         { role: 'user', content: content_submit },
  //         // { role: 'system', content: 'system should provide response in  '+variant+' diffrent variants' + append_c },
  //         { role: 'system', content: 'Response should be in '+language },
  //         { role: 'system', content: `You should use a ${tone} tone.` },
  //     ];
  //     // console.log(payload);
  //     // return;
  //     const response_ai = await OPENAPI(payload, max_tokens, temperature, user_token);
  //     setLoading(false)
  //     if(response_ai ==  'error'){
  //         toast.error(NotificationsToasts?.error_while_connecting?.notification);
  //         return;
  //     }
  //     if(response_ai ==  'ntoken'){
  //         toast.error(NotificationsToasts?.account_limit_reached?.notification);
  //         return;
  //     }
  //     const final_response = response_ai.choices[0].message.content;
  //     // if(variant > 1){
  //     //     var lines = final_response.split('##');
  //     // } else {
  //     //     var lines = [final_response];
  //     // }
  //     // console.log(lines);
  //     // setResponseAI(prevResponse => [...prevResponse, ...lines]);
  //     var lines = [final_response];
  //     if(responseAI.length > 0){
  //         setResponseAI(prevResponse => [...prevResponse, ...lines]);
  //     } else {
  //         console.log("i am one length")
  //         setResponseAI(lines)
  //     }
  // }

  return (
    <>
      <div className="container-wrappe">
        <div className="main-layout">
          <section className="tool-panel">
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <section className="tool-header">
                      <div>
                        <div className="svgIcon">
                          <img src={blogimage} />
                        </div>
                        <div>
                          <h2>{data?.tool_title}</h2>
                          <p>{data?.tool_description}</p>
                        </div>
                      </div>
                      <div className="planStatus">
                        <div>
                          <div>
                            <strong>{t("common.freePlan")} </strong>{" "}
                          </div>
                          <div>
                            <div className="progress">
                              <div
                                role="progressbar"
                                className="progress-bar"
                                aria-valuenow={8}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                style={{ width: "8%" }}
                              />
                            </div>{" "}
                          </div>
                          <div className="labelTxt">
                            <strong> 115 </strong><span>{t('countWords', {count: '1500'})}</span>
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                          >
                            {t("common.upgrade")}
                          </button>
                        </div>
                      </div>
                    </section>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="productFeatures">
                        {t("common.websiteHeadlineProductName")}
                      </label>{" "}
                      <textarea
                        maxLength={200}
                        placeholder={
                          data?.data?.length > 0 && data?.data[0]?.placeholder
                        }
                        name="blogAbout"
                        required=""
                        id="productFeatures"
                        className="form-control"
                        style={{ height: 115 }}
                        value={brandname}
                        onChange={(e) => setBrandName(e.target.value)}
                      />
                    </div>

                    <Common
                      onSelectionChange={handleDropdownChange}
                      onSelectionVariant={handleDropdownVariant}
                      onSelectionTone={handleDropdownTone}
                      onSelectionCreativity={handleDropdownCreativity}
                    />

                    <button
                      disabled={loading}
                      className="mt-3 w-100 btn btn-primary"
                      onClick={() => do_make_action()}
                    >
                      {loading ? (
                        <div className="flex">
                          <span>
                            {t("common.loading")}...
                          </span>
                        </div>
                      ) : (
                        <span>{t("common.generateContent")}</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="ai-board col-md-8 col">
                <ResponseDiv
                  loading={loading}
                  responseAI={responseAI}
                  contentCategory={type}
                />
              </div>
            </div>
          </section>
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: 16,
            pointerEvents: "none",
          }}
        />
      </div>
    </>
  );
}

export default Inner;
