import { useRoutes, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { doPost } from "../utils/apiCalls";
import { ToastContainer, toast } from "react-toastify";
// import Home from "../pages/Home";
import useAuth from "../hooks/useAuth";
import AuthLoading from "../pages/AuthLoading";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import VerifyEmail from "../pages/VerifyEmail";
import Forgot from "../pages/Forgot";
import OTP from "../pages/Forgot/Otp";
import AllTools from "../pages/Tools/All";
import Tools from "../pages/Tools";
import Content from "../pages/Content";
import Veriry from "../pages/Login/Verify";
import SuccessPage from "../pages/Pricing/success";
import Notification from "../pages/Notification/Notification";
import Chat from "../pages/chat/Chat";
import { Details, HelpCenter } from "../pages/Helpcenter";
import { setUnreadNotifications } from "../lib/Redux/slices/webData";
import { useDispatch, useSelector } from "react-redux";
// import MyContent from "../pages"

function Router() {
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    setInterval(() => {
      handleLogin();
    }, 5000);
  }, []);

  const dispatch = useDispatch();

  const GetNotifications = async () => {
    const payload = {
      token: getLoggedObject().token,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/unReadNotificationsCount"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
    } else {
      if (data.message == "success") {
        dispatch(setUnreadNotifications(data));
      } else {
        toast.error(data?.error);
      }
    }
  };

  const handleLogin = async () => {
    const language = localStorage.getItem("i18nextLng");
    const prev = localStorage.getItem("AIWORDAUTH");
    if (prev == undefined) {
      localStorage.clear();
      window.location.reload();
    }
    let token_data = JSON.parse(prev);
    if (token_data != null && token_data != "undefined") {
      const payLoad = { token: token_data?.token };
      const { isError, data } = await doPost(payLoad, "auth/check_login");
      if (data.error == "Invalid login credentials") {
        localStorage.removeItem("AIWORDAUTH");
        setLoggedObject(null);
        toast.error(NotificationsToasts?.session_expired?.notification);
        navigate("/login", { replace: true });
      } else {
        const path = window.location.pathname;
        const lastSlashIndex = path.lastIndexOf("/");
        const slugFromUrl =
          lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";
        if (slugFromUrl != "pricing" && slugFromUrl != "payment") {
          const final_data = data?.data;
          var current = new Date();
          var expiry_date = new Date(final_data?.expiry_date);
          GetNotifications();

          if (current > expiry_date) {
            toast.error(
              `${
                language == "en"
                  ? "Your subscription has been expired. Upgrade to access full functionality of the website!"
                  : "Ваша текущая подписка истекла. Для дальнейшего пользования нашей платформой, пожалуйста, выберите подходящий вариант подписки."
              }`
            );
            navigate("/pricing");
          } else {
          }
        }
      }
    }
  };

  const authRoutes = [
    { path: "/", element: <AuthLoading /> },
    { path: "/checkout", element: <SuccessPage /> },
    { path: "/dashboard", element: <Content /> },
    { path: "/:slug", element: <Content /> },
    { path: "/tools", element: <AllTools /> },
    { path: "/tools/:slug", element: <Tools /> },
    { path: "/templates/:slug", element: <Tools /> },
    { path: "/login", element: <Login /> },
    { path: "/notification", element: <Notification /> },
    { path: "/chat", element: <Chat /> },
    { path: "/helpcenter", element: <HelpCenter /> },
    { path: "/helpcenter/:slug", element: <Details /> },
    // {path: "*", element: <Dashboard/>},
  ];

  const unAuthRoutes = [
    // {path: "/", element: <Login/>},
    { path: "/", element: <AuthLoading /> },
    { path: "/login", element: <Login /> },
    { path: "/signup", element: <Signup /> },
    { path: "/verify/:slug", element: <Veriry /> },
    { path: "/verify/email/:slug", element: <VerifyEmail /> },
    { path: "/forgot/password", element: <Forgot /> },
    { path: "/Otp", element: <OTP /> },
    // {path: "/my-content", element: <MyContent />},
    // {path: "/tools", element: <AllTools/>},
    // {path: "/tools/:slug", element: <Tools/>},
    // {path: "/:slug", element: <Content/>},
    { path: "*", element: <Login /> },
  ];

  const routes = isLoggedIn ? authRoutes : unAuthRoutes;
  // const routes = unAuthRoutes;
  return useRoutes(routes);
}

export default Router;
