import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { validateEmail } from "../../utils/functions";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DELETE from './../../assets/images/delete.svg'

import { doPost, OPENAPI, CountWords, handleCopyClick } from "./../../utils/apiCalls";
import {Translator, Translate} from 'react-auto-translate';
import Common from './common';
import ResponseDiv from './Response';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function Inner({data = []}) {
    const { state } = useLocation();
  const { NotificationsToasts = null } = useSelector((state) => state.user);
    const [titleab, setTitleab] = useState(state?.v?.title);
    const [descab, setDesceab] = useState(state?.v.description);
    const [selectedfolder, setSelectedFolder] = useState(0);
    const {getLoggedObject} = useAuth();
    const [loading, setLoading] = useState(false);
    const [brandname, setBrandName] = useState(data?.data[0]?.textfield || "");
    const [description, setDescription] = useState(data?.data[1]?.textfield || "");
    const [headings, setHeadings] = useState([]);
    const [language, setLanguage] = useState('Russian');
    const [words, setWords] = useState('1000');
    const [creativity, setCreativity] =  useState('0.75');
    const [responseAI, setResponseAI] = useState([]);
    const [type, setType] = useState('generate_article');
    const [datahead, setDataHead] = useState([{"title":data?.data[2]?.textfield || ""}]);
    const { t } = useTranslation();

    const do_make_action = async () => {
        if(brandname == ''){
            toast.error(NotificationsToasts?.enter_article_title?.notification);
            return;
        }
        if(datahead.length == 0){
            toast.error(NotificationsToasts?.enter_article_subheading?.notification);
            return;
        }
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth' // Optional: adds smooth scrolling animation
          });
        do_loop_variants();
        
    }

    const do_loop_variants = async () => {
        setLoading(true)
        const user_token = getLoggedObject()?.token;
        const max_tokens = 10;
        const temperature = creativity;
        var all_sub_headings = datahead.map(item => item.title).join('\n');
        const content_submit = 'Create an impressive article of exact ('+words+') words where Article Title is  ('+brandname+')  based on below sub-headings ' + all_sub_headings;
        const payload = [
            { role: 'user', content: content_submit },
            { role: 'system', content: 'Response should be in '+language },
            { role: 'system', content: 'article should of exact '+words+ ' words' },
        ];
        // console.log(payload);
        // return;
        const response_ai = await OPENAPI(payload, max_tokens, temperature, user_token);
        setLoading(false)
        if(response_ai ==  'error'){
            toast.error(NotificationsToasts?.error_while_connecting?.notification);
            return;
        } 
        if(response_ai ==  'ntoken'){
            toast.error(NotificationsToasts?.account_limit_reached?.notification);
            return;
        } 
        const final_response = response_ai.choices[0].message.content;
        var lines = [final_response];
        setResponseAI(prevResponse => [...prevResponse, ...lines]);
    }


    const addNewItem = () => {
        // Create a new item with a default title or customize it as needed
        const newItem = { "title": "" };
        // Update the state by adding the new item to the datahead array
        setDataHead(prevDataHead => [...prevDataHead, newItem]);
    };
    const deleteItem = (index) => {
        // Create a copy of the datahead array
        const newDataHead = [...datahead];
        // Remove the item at the specified index
        newDataHead.splice(index, 1);
        // Update the state with the new array
        setDataHead(newDataHead);
    };

    const handleInputChange = (index, newValue) => {
        const newDataHead = [...datahead];
        newDataHead[index].title = newValue;
        console.log(newDataHead)
        setDataHead(newDataHead);
    };
  
  return (
    <>
            <div className="container-wrappe">
                <div className="container">
                <div className="article-post outEditor">
                    <section className="tool-panel">
                    <section className="header-txt mt-3 text-center">
                        <h2 className="text-center">{data?.tool_title}</h2>
                        <p>
                        {data?.tool_description}
                        </p>
                    </section>
                    <div className="row">
                        <div className="ai-board col-md-12">
                        <div className="infoTooltip2">
                            <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15px"
                            height="15px"
                            x="0px"
                            y="0px"
                            viewBox="0 0 330 330"
                            enableBackground="new 0 0 330 330;"
                            >
                            <g>
                                <g>
                                <g>
                                    <path
                                    d="M165,0.008C74.019,0.008,0,74.024,0,164.999c0,90.977,74.019,164.992,165,164.992s165-74.015,165-164.992
            C330,74.024,255.981,0.008,165,0.008z M165,299.992c-74.439,0-135-60.557-135-134.992S90.561,30.008,165,30.008
            s135,60.557,135,134.991C300,239.436,239.439,299.992,165,299.992z"
                                    />
                                    <path
                                    d="M165,130.008c-8.284,0-15,6.716-15,15v99.983c0,8.284,6.716,15,15,15s15-6.716,15-15v-99.983
            C180,136.725,173.284,130.008,165,130.008z"
                                    />
                                    <path
                                    d="M165,70.011c-3.95,0-7.811,1.6-10.61,4.39c-2.79,2.79-4.39,6.66-4.39,10.61s1.6,7.81,4.39,10.61
            c2.79,2.79,6.66,4.39,10.61,4.39s7.81-1.6,10.609-4.39c2.79-2.8,4.391-6.66,4.391-10.61s-1.601-7.82-4.391-10.61
            C172.81,71.61,168.95,70.011,165,70.011z"
                                    />
                                </g>
                                </g>
                            </g>{" "}
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            </svg>
                            <span className="tooltiptext">
                                {t("common.automaticallyGeneratedContentUsingAI")}
                            </span>
                        </div>
                        <div className="card">
                            <div className="ai-board-body card-body">
                            <div className="ai-section height-align text-center">
                                <div className="float-left w-100 mb-1 pt-4">
                                <div className="row">
                                    <div className="mb-3 col-12 text-left">
                                    <label className="form-label">{t("common.articleTitle")}</label>
                                    <input
                                        name="blogTitle"
                                        placeholder={data?.data?.length > 0 && data?.data[0]?.placeholder}
                                        type="text"
                                        className="col-md-12 form-control"
                                        value={brandname}
                                        onChange={(e)=>setBrandName(e.target.value)}
                                    />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-12 col-md-6 text-left">
                                    <label className="form-label">
                                        {t("common.keywordsOptional")}
                                    </label>
                                    <input
                                        name="blogKeywords"
                                        placeholder={data?.data?.length > 0 && data?.data[1]?.placeholder}
                                        type="text"
                                        className="form-control"
                                        value={description}
                                        onChange={(e)=>setDescription(e.target.value)}
                                    />
                                    </div>
                                    <div className="mb-3 col-12 col-md-6 text-left">
                                    <label className="form-label">{t("common.language")}</label>
                                    <select
                                        name="language"
                                        className="form-control form-select"
                                        value={language}
                                        onChange={(e)=>setLanguage(e.target.value)}
                                    >
                                        <option value="English (US)">English (US)</option>
                                        <option value="English (UK)">English (UK)</option>
                                        <option value="French">French</option>
                                        <option value="Spanish">Spanish</option>
                                        <option value="German">German</option>
                                        <option value="Italian">Italian</option>
                                        <option value="Dutch">Dutch</option>
                                        <option value="Arabic">Arabic</option>
                                        <option value="Portuguese">Portuguese</option>
                                        <option value="Portuguese (BR)">
                                        Portuguese (BR)
                                        </option>
                                        <option value="Swedish">Swedish</option>
                                        <option value="Norwegian">Norwegian</option>
                                        <option value="Danish">Danish</option>
                                        <option value="Finnish">Finnish</option>
                                        <option value="Romanian">Romanian</option>
                                        <option value="Czech">Czech</option>
                                        <option value="Slovak">Slovak</option>
                                        <option value="Slovenian">Slovenian</option>
                                        <option value="Hungarian">Hungarian</option>
                                        <option value="Hebrew">Hebrew</option>
                                        <option value="Polish">Polish</option>
                                        <option value="Turkish">Turkish</option>
                                        <option value="Russian">Russian</option>
                                        <option value="Hindi">Hindi</option>
                                        <option value="Thai">Thai</option>
                                        <option value="Japonese">Japonese</option>
                                        <option value="Chinese (Simplified)">
                                        Chinese (Simplified)
                                        </option>
                                        <option value="Korean">Korean</option>
                                        <option value="Indonesian">Indonesian</option>
                                        <option value="Greek">Greek</option>
                                        <option value="Bulgarian">Bulgarian</option>
                                        <option value="Ukrainian">Ukrainian</option>
                                        <option value="Estonian">Estonian</option>
                                        <option value="Latvian">Latvian</option>
                                        <option value="Lithuanian">Lithuanian</option>
                                        <option value="Croatian">Croatian</option>
                                        <option value="Serbian">Serbian</option>
                                        <option value="Bosnian">Bosnian</option>
                                        <option value="Macedonian">Macedonian</option>
                                        <option value="Albanian">Albanian</option>
                                        <option value="Vietnamese">Vietnamese</option>
                                        <option value="Hindi">Hindi</option>
                                        <option value="Bengali">Bengali</option>
                                        <option value="Telugu">Telugu</option>
                                        <option value="Marathi">Marathi</option>
                                        <option value="Tamil">Tamil</option>
                                        <option value="Urdu">Urdu</option>
                                        <option value="Gujarati">Gujarati</option>
                                        <option value="Kannada">Kannada</option>
                                        <option value="Odia">Odia</option>
                                        <option value="Malayalam">Malayalam</option>
                                    </select>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-2">
                                <div className="col-md-6">
                                    <label className="form-label">
                                    {t("common.articleSubheadings")}
                                    </label>
                                </div>
                                </div>
                                {
                                    datahead.map((v, i) => (
                                    <div className="outlineDroppable">
                                    <div
                                        data-rbd-droppable-id="droppable"
                                        data-rbd-droppable-context-id={4}
                                    >
                                        <div
                                        className="ai-section height-align text-center articleOutlineInput"
                                        data-rbd-draggable-context-id={4}
                                        data-rbd-draggable-id={0}
                                        tabIndex={0}
                                        role="button"
                                        aria-describedby="rbd-hidden-text-4-hidden-text-12"
                                        data-rbd-drag-handle-draggable-id={0}
                                        data-rbd-drag-handle-context-id={4}
                                        draggable="false"
                                        >
                                        <div className="ai-content flexbox" style={{padding:"0"}}>
                                            
                                            <input
                                            className="subHeading"
                                            id={0}
                                            placeholder={data?.data?.length > 0 && data?.data[2]?.placeholder}
                                            value={v.title}
                                            onChange={(e) => handleInputChange(i, e.target.value)}
                                            style={{
                                                border:"0",
                                                background:"transparent",
                                                padding:'10px'
                                            }}
                                            />
                                            <div className="selectBtn">
                                        {
                                            i > 0 &&
                                        
                                            <button
                                                type="button"
                                                id={0}
                                                className="deleteItem btn btn-outline-secondary btn-sm mr-2"
                                                onClick={() => deleteItem(i)}
                                            >
                                                <img src={DELETE} />
                                            </button>
                                        }
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    ))
                                }
                                <div />
                            </div>
                            <button
                                type="button"
                                className="addItem mr-0 float-right btn btn-outline-secondary btn-sm"
                                onClick={()=>addNewItem()}
                            >
                                + {t("common.addNew")}
                            </button>
                            </div>
                        </div>
                        <div className="mainButtonss mt-3 pb-1" />
                        <div className="mainButtons pb-2">
                            <select
                            name="wordRange"
                            className="form-control form-select"
                            id="wordRange"
                            value={words}
                            onChange={(e)=>setWords(e.target.value)}
                            >
                            <option value={1000}>
                                1000+ {t("common.words")}
                            </option>
                            <option value={2000}>2000+{t("common.words")}</option>
                            <option value={3000}>3000+ {t("common.words")}</option>
                            </select>
                            <button disabled={loading} className="btn btn-success btn-md" onClick={()=>do_make_action()}>
                                {loading?
                                    <div className="flex">
                                        <span>{t("common.loading")}...</span>
                                    </div>
                                :
                                    <span>{t("common.generateContent")}</span>
                                }
                            </button>
                        </div>
                        </div>
                    </div>
                    </section>
                </div>
                </div>

                <div className="ai-board col-md-12 col">
                    <ResponseDiv loading={loading} responseAI={responseAI} contentCategory={type} />
                </div>
            </div>

    </>
);
}

export default Inner;
