import React, { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { OPENAPI } from "./../../utils/apiCalls";
import { Translate } from "react-auto-translate";
import Common from "./common";
import ResponseDiv from "./Response";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Inner({ data = [] }) {
  const { state } = useLocation();
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const [blogimage, setBlogImage] = useState(state?.v.image);

  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [brandname, setBrandName] = useState(data?.data[0]?.textfield || "");
  const [description, setDescription] = useState(
    data?.data[1]?.textfield || ""
  );
  const [year, setYear] = useState(data?.data[2]?.textfield || "");

  const [language, setLanguage] = useState("Russian");
  const [variant, setVariant] = useState(1);
  const [tone, setTone] = useState("Formal");
  const [creativity, setCreativity] = useState("0.75");
  const [responseAI, setResponseAI] = useState([]);
  const [type, setType] = useState("company_vision");

  const handleDropdownChange = (selectedValue) => {
    setLanguage(selectedValue);
  };
  const handleDropdownVariant = (selectedValue) => {
    setVariant(selectedValue);
  };
  const handleDropdownTone = (selectedValue) => {
    setTone(selectedValue);
  };
  const handleDropdownCreativity = (selectedValue) => {
    setCreativity(selectedValue);
  };

  const do_loop_variants = async () => {
    setLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = creativity;

    if (year != "") {
      var c_years = " And also Target audience is  " + year;
    }
    const content_submit =
      "Generate Company Vision content where Product Name is (" +
      brandname +
      ") and Description is (" +
      description +
      ") " +
      c_years;
    const payload = [
      { role: "user", content: content_submit },
      { role: "system", content: "Response should be in " + language },
      { role: "system", content: `You should use a ${tone} tone.` },
    ];
    // console.log(payload);
    // return;
    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );
    setLoading(false);
    if (response_ai == "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting?.notification
      );
      return;
    }
    if (response_ai == "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }
    const final_response = response_ai.choices[0].message.content;
    var lines = [final_response];
    setResponseAI((prevResponse) => [...prevResponse, ...lines]);
  };

  const do_make_action = async () => {
    if (brandname == "") {
      toast.error(NotificationsToasts?.enter_product_name?.notification);
      return;
    }
    if (description == "") {
      toast.error(NotificationsToasts?.enter_product_description?.notification);
      return;
    }
    var user_variants = variant;
    for (let i = 0; i < user_variants; i++) {
      do_loop_variants();
    }
  };

  return (
    <>
      <div className="container-wrappe">
        <div className="main-layout">
          <section className="tool-panel">
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <section className="tool-header">
                      <div>
                        <div className="svgIcon icoo">
                          <img src={blogimage} />
                        </div>
                        <div>
                          <h2>{data?.tool_title}</h2>
                          <p>{data?.tool_description}</p>
                        </div>
                      </div>
                    </section>
                    <form className="">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="brandName">
                          {t("common.productName")}
                        </label>
                        <input
                          required=""
                          name="projectValue1"
                          placeholder={
                            data?.data?.length > 0 && data?.data[0]?.placeholder
                          }
                          type="text"
                          id="brandName"
                          className="form-control"
                          value={brandname}
                          onChange={(e) => setBrandName(e.target.value)}
                        />
                      </div>
                      <div className="mt-3 mb-3">
                        <label className="form-label" htmlFor="projectValue2">
                          {t("common.productDescription")}
                        </label>{" "}
                        <textarea
                          maxLength={400}
                          placeholder={
                            data?.data?.length > 0 && data?.data[1]?.placeholder
                          }
                          name="projectValue2"
                          required=""
                          id="projectValue2"
                          className="form-control"
                          style={{ height: 115 }}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="audience">
                          {t("common.audienceOptional")}
                        </label>
                        <input
                          name="projectValue3"
                          placeholder={
                            data?.data?.length > 0 && data?.data[2]?.placeholder
                          }
                          type="text"
                          id="audience"
                          className="form-control"
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                        />
                      </div>

                      <Common
                        onSelectionChange={handleDropdownChange}
                        onSelectionVariant={handleDropdownVariant}
                        onSelectionTone={handleDropdownTone}
                        onSelectionCreativity={handleDropdownCreativity}
                      />

                      <button
                        disabled={loading}
                        className="mt-3 w-100 btn btn-primary"
                        onClick={() => do_make_action()}
                      >
                        {loading ? (
                          <div className="flex">
                            <span>
                              {t("common.loading")}...
                            </span>
                          </div>
                        ) : (
                          <span>{t("common.generateContent")}</span>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="ai-board col-md-8 col">
                <ResponseDiv
                  loading={loading}
                  responseAI={responseAI}
                  contentCategory={type}
                />
              </div>
            </div>
          </section>
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: 16,
            pointerEvents: "none",
          }}
        />
      </div>
    </>
  );
}

export default Inner;
