import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar'

function AuthLoading() {
    const ref = useRef(null)
    const [token, setToken] = React.useState(null);
    const {getLoggedObject, setLoggedObject, isLoggedIn, checkLogin} = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    useEffect(()=>{
        ref.current.continuousStart();
        if(isLoggedIn){
            // console.log("I came here");
            // window.location.reload();
            navigate('/dashboard');
            return;
        } else {
            navigate('/login');
        }
    },[isLoggedIn])
   
    return (
        <>
            <div className="outer_loading_class">
                <LoadingBar shadow={true} height={3}  color='#f11946' ref={ref} />
            </div>
        </>
    )
}

export default AuthLoading;