import React, { useEffect, useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { toast } from "react-toastify";
import { Translate } from "react-auto-translate";
import DELICON from "./../../assets/images/delete.svg";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

function Inner() {
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const { getLoggedObject } = useAuth();
  const [headlines, setHeadLines] = useState([]);
  const [content, setContent] = useState([]);
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    get_user_content();
  }, []);
  const get_user_content = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/get_user_all_content"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setHeadLines(data?.headlines);
        // setContent(data?.data)
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const get_specific_category_content = async (val) => {
    const payload = {
      token: getLoggedObject()?.token,
      val: val,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/get_specific_category_content"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setSteps(val);
        setLoading(false);
        setContent(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const delete_user_content = async (id, val) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: id,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/delete_specific_user_content"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success(
          NotificationsToasts?.content_deleted_successfully?.notification
        );
        get_user_content();
        get_specific_category_content(val);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <>
      <div className="container-wrappe">
        {" "}
        <div className="main-layout container">
          <div className="header-txt text-center">
            <h2>
              {t("myContent.label")}
            </h2>
          </div>
          <div className="blog-post-projects">
            <div className="row">
              <div className="tabButtons sideBar col-sm-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      {t("myContent.category")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div
                      className="flex-column  nav nav-pills"
                      role="tablist"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="nav-item">
                        {headlines.map((v, i) => (
                          <div
                            className={`nav-link ${
                              steps == v.title ? "active" : ""
                            }`}
                            onClick={() =>
                              get_specific_category_content(v.title)
                            }
                            style={{ textTransform: "capitalize" }}
                          >
                            <Translate>{v.title.replace(/_/g, " ")}</Translate>{" "}
                            <b className="float-right">{v.count}</b>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="tableContent card">
                  <div className="card-body">
                    <div className="tabContainer tab-content">
                      <div
                        role="tabpanel"
                        id="react-aria4293207873-2-tabpane-article-writer"
                        aria-labelledby="react-aria4293207873-2-tab-article-writer"
                        className="fade tab-pane  active show"
                      >
                        <table className="table tableFixHead table-hover myTemplates">
                          <thead>
                            <tr>
                              <th width="70%">
                                {t("myContent.title")}
                              </th>
                              <th width="5%">
                                {t("myContent.word")}
                              </th>
                              <th width="15%">
                                {t("myContent.date")}
                              </th>
                              <th width="15%">
                                {t("myContent.actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {content.length == 0 && (
                              <tr className="noResults">
                                <td colSpan={4}>
                                  {" "}
                                  <span>
                                    {t("myContent.notContentFound")}
                                  </span>
                                </td>
                              </tr>
                            )}
                            {content.length > 0 && (
                              <>
                                {content.map((v, i) => (
                                  <tr>
                                    <td>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: v.content,
                                        }}
                                      ></span>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {v.words}
                                    </td>
                                    <td style={{ fontSize: "11px" }}>
                                      {v.date}
                                    </td>
                                    <td valign="center">
                                      <button
                                        type="button"
                                        className="ml-1  btn btn-outline-secondary btn-sm"
                                        onClick={() =>
                                          delete_user_content(v.id, v.heading)
                                        }
                                      >
                                        <img src={DELICON} />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p />
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: 16,
            pointerEvents: "none",
          }}
        />{" "}
      </div>
    </>
  );
}

export default Inner;
