import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "./../../assets/images/logo.png";
import PROFILE_IMAGE from "./../../assets/images/profile.png";
import { AiOutlineLogout } from "react-icons/ai";
import Header from "./../../base-components/Header";
import Footer from "./../../base-components/Footer";
import SideBar from "./../../base-components/SideBar";
import PLUS from "./../../assets/images/plus.svg";
import EDIT from "./../../assets/images/edit.svg";
import RARROW from "./../../assets/images/arrowsquareright.svg";
import NavBar from "./../../base-components/NavBar";
import NavBarLOGIN from "./../../base-components/NavBar/navbar_login";
import { TiInfo } from "react-icons/ti";




import Article from "./article_writer";
import KeywordResearch from "./keyword_research";
import BlodIdeas from "./blog_ideas";
import BlogTitle from "./blog_title";
import BlogIntro from "./blog_intro";
import BlogOutline from "./blog_outline";
import BlogSection from "./blog_section";

import BlogConclusion from "./blog_conclusion";
import ArticleRewriter from "./article_rewriter";
import SentenceRewriter from "./sentence_rewriter";
import SentenceExpandar from "./sentence_expander";
import ProsCons from "./proscons";
import ParagraphWriter from "./paragraph_writer";
import ParagraphPoints from "./paragraph_points";
import Parapharase from "./parapharase";

import WebsiteHeadlines from "./website_headlines";
import WebsiteSubHeadlines from "./website_sub_headlines";
import WebsiteMeta from "./website_meta";
import WebsiteDescription from "./website_description";
import FAQ from "./faqs";
import About from "./about";
import Company from "./company";
import CompanyMission from "./company_mission";
import CallAction from "./call_action";
import Aida from "./aida";
import Quest from "./quest";
import Pain from "./pain";
import Dashboard from "./../Dashboard";
import Profile from "./../Profile";
import Pricing from "./../Pricing";
import Payment from "./../Pricing/Payment";
import ChangePassword from "./../Profile/Password";
import Img from "./image";

import ColdEmail from "./Cold_email";
import WelcomeEmil from "./Welcome_email";
import CancellationEmail from "./Cancellation_email";
import Followup from "./followup";
import EmailSubject from "./Email_Subject";
import Thankyou from "./Thankyou_note";
import GrammerCheck from "./Grammer_check";
import TextSummarize from "./Text_Summarize";
import StoryCreator from "./Story_creator";
import Value from "./Value";
import Newsletters from "./Newsletters";
import NameGenerator from "./Name_Generator";
import Press from "./Press";
import Essay from "./Essay";
import EssayOutline from "./Essay_Outline";
import Flexy from "./Flexy";
import FacebookAds from "./Facebook_Ads";
import GoogleAd from "./GoogleAd";
import LinkedIN from "./LinkedIn";
import ProductDescription from "./ProductDescription";
import AmazonDesc from "./Amazon_PDesc";
import AmazonBullets from "./Amazon_Bullets";
import AmazonAds from "./Amazon_Ads";
import AmazonTitle from "./AmazonTitle";
import ProductReview from "./ProductReview";
import CustomerResponse from "./CustomerResponse";
import Youtubevideo from "./Youtubevideo";
import YoutubevideoDesc from "./YoutubevideoDesc";
import YoutubevideoTags from "./YoutubevideoTags";
import YoutubevideoScript from "./YoutubevideoScript";
import YoutubeComment from "./YoutubeComment";
import QuoraAnswers from "./QuoraAnswers";
import SocialPost from "./SocialPost";
import InstagramCaption from "./InstagramCaption";
import InstaTags from "./InstaTags";
import LinkedinPost from "./LinkedinPost";
import TwitterThreads from "./TwitterThreads";
import TwitterGenerator from "./TwitterGenerator";
import Wildberries from "./Wildberries";
import Ozon from "./Ozon";
import Yandex from "./Yandex";
import Vk from "./Vk";
import Odnoklasniki from "./Odnoklasniki";
import BlogPost from "./blog_post";
import MyContent from "./my_content";
import Statusupdate from "./status_update";
import RequestEmail from "./Request_Email";
import feedbackEmail from "./Feedback_Email";
import ReportingEmail from "./Reporting_Email";
import LeaveEmail from "./Leave_Email";
import SickLeave from "./Sick_Leave";
import WorkHomeEmail from "./WorkHomeEmail";
import ResignnationLetter from "./ResignationLetter";
import RefrenceLetter from "./ReferenceLetter";
import SalesEmail from "./SalesEmail";
import ReplyEmail from "./ReplyEmail";
import IntroEmail from "./IntroductionEmail";
import TestimonialEmail from "./TestimonialEmail";
import SpecialOfferEmail from "./SpecialOfferEmail";
import ReviewRequestEmail from "./ReviewRequestEmail";
import CuratedEmail from "./CuratedEmail";
import NewProductEmail from "./NewProductEmail";
import AbandonedEmail from "./AbandonedCartEmail";
import ProgressEmail from "./ProgressEmail";
import ConfirmationEmail from "./ConfirmationEmail";
import AppriciationEmail from "./AppreciationEmail";
import MileStoneEmail from "./MileStoneEmail";
import GernalEmail from "./GernalEmail";
import NewsLetterEmail from "./NewsLetterEmail";

import PersonalStatement from "./Personal_statement";
import ProfessionalBio from "./ProfessionalBio";
import StatementPurpose from "./StatementPurpose";
import GetWellSoon from "./GetWellSoon";
import GoodByeLetter from "./GoodByeLetter";
import CelebrationLetter from "./CelebrationLetter";
import PersonalLetter from "./PersonalLetter";
import AnniversaryLetter from "./AnniversaryLetter";
import CongratulationLetter from "./CongratulationLetter";
import GrievanceLetter from "./GrievanceLetter";
import CondolenceLetter from "./CondolenceLetter";
import ApologyLetter from "./ApologyLetter";
import LeaveLetter from "./LeaveLetter";
import HolidaysLetter from "./HolidaysLetter";
import InterviewFollowUp from "./InterviewFollowUp";
import OfferLetter from "./OfferLetter";
import AppointmentLetter from "./AppointmentLetter";
import AppraisalLetter from "./AppraisalLetter";
import PromotionLetter from "./PromotionLetter";
import ExperienceLetter from "./ExperienceLetter";
import BonafideLetter from "./BonafideLetter";
import PayslipLetter from "./PayslipLetter";
import RelievingLetter from "./RelievingLetter";
import EmploymentCertificate from "./EmploymentCertificate";
import JobDescription from "./JobDescription";
import DigitalMarketingPlan from "./DigitalMarketingPlan";
import BusinessPlan from "./BusinessPlan";
import RealEstateListing from "./RealEstateListing";
import PressRelease from "./PressRelease";
import ResumeSummary from "./ResumeSummary";
import CareerObjective from "./CareerObjective";
import CoverLetter from "./CoverLetter";
import TeacherCover from "./TeacherCover";
import CustomerService from "./CustomerService";
import NurseCoverLetter from "./NurseCoverLetter";
import SalesCover from "./SalesCover";
// import NewsLetterEmail from "./NewsLetterEmail";
// import NewsLetterEmail from "./NewsLetterEmail";
// import NewsLetterEmail from "./NewsLetterEmail";
// import NewsLetterEmail from "./NewsLetterEmail";
// import NewsLetterEmail from "./NewsLetterEmail";
// import NewsLetterEmail from "./NewsLetterEmail";
// import NewsLetterEmail from "./NewsLetterEmail";
// import NewsLetterEmail from "./NewsLetterEmail";
import { Translator, Translate } from "react-auto-translate";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// import Img from './image'
// import Img from './image'

const componentMapping = {
  "article-writer": Article,
  "keyword-research": KeywordResearch,
  "blog-ideas": BlodIdeas,
  "blog-title": BlogTitle,
  "blog-intro": BlogIntro,
  "blog-outline": BlogOutline,
  "blog-section": BlogSection,
  "blog-conclusion": BlogConclusion,
  "article-rewriter": ArticleRewriter,
  "sentence-rewriter": SentenceRewriter,
  "sentence-expander": SentenceExpandar,
  "pros-cons": ProsCons,
  "paragraph-writer": ParagraphWriter,
  "paragraph-points": ParagraphPoints,
  paraphrase: Parapharase,
  "website-headlines": WebsiteHeadlines,
  "website-sub-headline": WebsiteSubHeadlines,
  "meta-title": WebsiteMeta,
  "meta-description": WebsiteDescription,
  faqs: FAQ,
  "about-us": About,
  "company-vision": Company,
  "company-mission": CompanyMission,
  "call-to-action": CallAction,
  "aida-content": Aida,
  "quest-formula": Quest,
  "pain-agitate-solution": Pain,
  dashboard: Dashboard,
  profile: Profile,
  pricing: Pricing,
  payment: Payment,
  "change-password": ChangePassword,
  "image-generator": Img,
  "cold-email": ColdEmail,
  "welcome-email": WelcomeEmil,
  "cancellation-email": CancellationEmail,
  "follow-up-email": Followup,
  "email-subject-line": EmailSubject,
  "thank-you-note": Thankyou,
  "grammar-checker": GrammerCheck,
  "text-summarizer": TextSummarize,
  "story-creator": StoryCreator,
  "value-proposition": Value,
  newsletters: Newsletters,
  "name-generator": NameGenerator,
  "press-releases": Press,
  "essay-intros": Essay,
  "essay-outlines": EssayOutline,
  "flexy-template": Flexy,
  "facebook-ad": FacebookAds,
  "google-ad": GoogleAd,
  "linkedin-ad": LinkedIN,
  "product-description": ProductDescription,
  "amazon-product-description": AmazonDesc,
  "amazon-product-bullet-points": AmazonBullets,
  "amazon-ad-headline": AmazonAds,
  "amazon-product-title": AmazonTitle,
  "product-review": ProductReview,
  "customer-review-response": CustomerResponse,
  "youtube-video-title": Youtubevideo,
  "youtube-video-description": YoutubevideoDesc,
  "youtube-video-tags": YoutubevideoTags,
  "youtube-video-script": YoutubevideoScript,
  "youtube-comments-responder": YoutubeComment,
  "quora-answers": QuoraAnswers,
  "social-media-post": SocialPost,
  "instagram-caption": InstagramCaption,
  "instagram-hashtags": InstaTags,
  "linkedin-post": LinkedinPost,
  "twitter-threads": TwitterThreads,
  "twitter-generator": TwitterGenerator,
  wildberries: Wildberries,
  ozon: Ozon,
  yandex: Yandex,
  vk: Vk,
  Odnoklasniki: Odnoklasniki,
  "blog-post": BlogPost,
  "my-content": MyContent,
  payment: Payment,
  "status-update-email": Statusupdate,
  "requesting-email": RequestEmail,
  "feedback-email": feedbackEmail,
  "reporting-email": ReportingEmail,
  "leave-email": LeaveEmail,
  "sick-leave-email": SickLeave,
  "work-from-home-request-email": WorkHomeEmail,
  "resignation-letter": ResignnationLetter,
  "reference-letter": RefrenceLetter,
  "sales-email": SalesEmail,
  "reply-email": ReplyEmail,
  "introduction-email": IntroEmail,
  "testimonial-email": TestimonialEmail,
  "special-offer-email": SpecialOfferEmail,
  "Review-request-email": ReviewRequestEmail,
  "curated-content-email": CuratedEmail,
  "new-product-announcement": NewProductEmail,
  "abandoned-cart-email": AbandonedEmail,
  "progress-email": ProgressEmail,
  "confirmation-email": ConfirmationEmail,
  "appreciation-email": AppriciationEmail,
  "milestone-email": MileStoneEmail,
  "general-email": GernalEmail,
  "newsletter-email": NewsLetterEmail,

  "personal-statement": PersonalStatement,
  "professional-bio": ProfessionalBio,
  "statement-purpose": StatementPurpose,
  "get-well-soon-letter": GetWellSoon,
  "goodbye-letter": GoodByeLetter,
  "celebration-letter": CelebrationLetter,
  "personal-letter": PersonalLetter,
  "anniversary-letter": AnniversaryLetter,
  "congratulation-letter": CongratulationLetter,
  "grievance-letter": GrievanceLetter,
  "condolence-letter": CondolenceLetter,
  "apology-letter": ApologyLetter,
  "leave-letter": LeaveLetter,
  "holidays-letter": HolidaysLetter,
  "interview-follow-up-letter": InterviewFollowUp,
  "offer-letter": OfferLetter,
  "appointment-letter": AppointmentLetter,
  "appraisal-letter": AppraisalLetter,
  "promotion-letter": PromotionLetter,
  "experience-letter": ExperienceLetter,
  "bonafide-letter": BonafideLetter,
  "payslip-letter": PayslipLetter,
  "relieving-letter": RelievingLetter,
  "employment-certificate": EmploymentCertificate,
  "job-description": JobDescription,
  "digital-marketing-plan": DigitalMarketingPlan,
  "business-plan": BusinessPlan,
  "real-estate-listing": RealEstateListing,
  "press-release": PressRelease,
  "resume-summary": ResumeSummary,
  "career-objective": CareerObjective,
  "cover-letter": CoverLetter,
  "teacher-cover-letter": TeacherCover,
  "customer-service-cover-letter": CustomerService,
  "nurse-cover-letter": NurseCoverLetter,
  "sales-cover-letter": SalesCover,
  // "newsletter": NewsLetterEmail,
  // "newsletter": NewsLetterEmail,
  // "newsletter": NewsLetterEmail,
  // "newsletter": NewsLetterEmail,
};

function Jobs() {
  const [selectedfolder, setSelectedFolder] = useState(0);
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";
  const DynamicComponent = componentMapping[slugFromUrl];

  const Get_Place_Holders = async (langg) => {
    const payload = {
      token: getLoggedObject()?.token,
      slug: slugFromUrl,
      lang: langg === "ru" ? 2 : 1,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/getToolPlaceholders");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
    } else {
      if (data.message == "success") {
        setData(data);
      } else {
        toast.error(data?.error);
      }
    }
  };

  const [Pro, setPro] = useState(false);

  useEffect(() => {
    if (
      slugFromUrl != "dashboard" &&
      slugFromUrl != "pricing" &&
      slugFromUrl != "profile" &&
      slugFromUrl != "change-password"
    ) {
      setLoading(false);
      if (getLoggedObject().plan_info == "free" && data?.tool_is_pro == 1) {
        setPro(true);
        setLoading(false);
      } 
      else{
        setPro(false);
        setLoading(false);
      }
    } else {
      setPro(false);
      setLoading(false);
    }
  }, [slugFromUrl, data]);

  useEffect(() => {
    const langg = localStorage.getItem("i18nextLng");
    Get_Place_Holders(langg);
  }, [slugFromUrl]);

  return (
    <>
      <div className="layout-container container-fluid">
        <NavBarLOGIN />
        <div className="layout-row row">
          <SideBar />

          <div className="scrollable-body col-md-9 col-12">
            {DynamicComponent && !loading && !Pro ? (
              <DynamicComponent data={data} />
            ) : loading ? (
              <>
              <div className="custom_flex_loader_content">
                <div className="loader"></div>
              </div>
              </>
              
            ) : (
              Pro && (
                <div
                  style={{ marginTop: "100px", padding: "40px", textAlign:"center" }}
                  class="d-flex flex-column align-items-center custom_pro_border"
                  role="alert"
                >
                  <div>
                    <p><TiInfo size={40}  /></p>
                    <h3 className="pro_he">{t("common.pro")}</h3>
                    {t("common.sorryThisToolIsAvailablefor")} <span><b>{t("common.pro")}</b></span> {t("common.users")}.
                  </div>
                  <Link to={"/pricing"}>
                    <button className="mt-3 btn btn-primary ">
                      {t("common.upgradePlan")}
                    </button>
                  </Link>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
