import { useEffect, useState } from "react";
import { doPost } from "../utils/apiCalls";

export default function useAuth() {
  let prev = localStorage.getItem("AIWORDAUTH");
  const [loggedObject, setLoggedObjectFinal] = useState(prev);

  const checkLogin = async () => {
    const payLoad = { token: getLoggedObject()?.token };
    const { isError, data } = await doPost(payLoad, "auth/check_login");
    if (!isError) {
      let dd = data?.data;
      // dd.token=payLoad;
      setLoggedObject(dd);
    }
    return !isError;
  };

  const setLoggedObject = (obj) => {
    console.log("abc");
    if (obj === null) {
      setLoggedObjectFinal(null);
    } else {
      setLoggedObjectFinal(JSON.stringify(obj));
      localStorage.setItem("AIWORDAUTH", JSON.stringify(obj));
      prev = loggedObject;
    }
  };
  const getLoggedObject = () => {
    if (loggedObject != "undefined") {
      return JSON.parse(loggedObject);
    } else {
      return null;
    }
  };
  const isLoggedIn = getLoggedObject() !== null;
  useEffect(() => {
    localStorage.setItem("AIWORDAUTH", loggedObject);
    prev = loggedObject;
  }, [loggedObject]);

  return { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin };
}
