import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "./../../assets/images/logo.png";

function NavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [darkmode, setDarkMode] = useState(1);
  const [dropdown, setDropdown] = useState(false);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  return (
    <>
      <nav className="header-nav navbar navbar-expand-lg navbar-light">
        <span className="navbar-brand">
          <a className="logo" href="/dashboard">
            <img src={LOGO} />
          </a>
        </span>
        <button
          aria-controls="basic-navbar-nav"
          type="button"
          aria-label="Toggle navigation"
          className="navbar-toggler collapsed"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="justify-content-end navbar-collapse collapse"
          id="basic-navbar-nav"
          style={{}}
        >
          <div className="center-nav navbar-nav" />
          <div className="myprofile-link navbar-nav">
            <a
              variant="primary"
              className="head-login-btn btn-primary"
              href="/login"
            >
              Login
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}
export default NavBar;
