import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Favroute: [],
  notifications: 0,
};

const webDataSlice = createSlice({
  name: "WebData",
  initialState,
  reducers: {
    setFaroutesLists: (state, action) => {
      state.Favroute = action.payload;
    },
    setUnreadNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const { setFaroutesLists, setUnreadNotifications } =
  webDataSlice.actions;
export const selectWebData = (state) => state.WebData.value;
export default webDataSlice.reducer;
