import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import enJSON from './locale/en.json'
import ptJSON from './locale/ru.json'
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enJSON },
    ru: { translation: ptJSON },
  },
  lng: "en",
});