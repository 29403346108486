import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost, do_update_token } from "./../../utils/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Translate } from "react-auto-translate";
import { setTokens } from "../../lib/Redux/slices/userslice";
import { useDispatch, useSelector } from "react-redux";
import { FaRegStar, FaStar } from "react-icons/fa";
import {
  setFaroutesLists,
  setNotificationsTostify,
} from "../../lib/Redux/slices/webData";
import { useTranslation } from 'react-i18next';

function Jobs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getLoggedObject } = useAuth();
  const [selectedfolder, setSelectedFolder] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [Favroute, setFavroute] = useState([]);
  const [total, setTotal] = useState(0);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  useEffect(() => {
    handle_content();
  }, [currentLanguage]);
  const handle_content = async () => {
    // const lang = localStorage.getItem("i18nextLng");
    const payload = {
      token: getLoggedObject().token,
      slug: 1,
      lang: currentLanguage,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_popular_categories"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setTotal(data?.total);
        setFavroute(data?.favourite_list);
        dispatch(setFaroutesLists(data?.favourite_list));
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handleFavUnfav = async (id) => {
    const payload = {
      token: getLoggedObject().token,
      category_id: id,
      lang: currentLanguage,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/categoryFavouriteStore"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.status) {
        toast.success(data?.message);
        setLoading(false);
        handle_content();
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  useEffect(() => {
    const fetch = async () => {
      const response = await do_update_token(0, getLoggedObject()?.token ,NotificationsToasts);
      if (response) {
        dispatch(setTokens(response));
      }
    };
    fetch();
  }, []);

  
  const [starFavroute, setstarFavroute] = useState(false);

  return (
    <>
      {/* <div className="layout-container container-fluid"> */}
      {/* <NavBar /> */}
      {/* <div className="layout-row row"> */}
      {/* <SideBar /> */}
      {/* <div className="scrollable-body col-md-9 col-12"> */}
      <div className="container-wrappe">
        <div className="main-layout dashboar-page container">
          <div className="header-txt">
            <h2 className="mt-2 mb-3 text-left">
            👋 <span>{t('common.hey')} {getLoggedObject()?.fullname}</span>{" "}
              <span className="text-left dashCaption">
                {" "}
                - <span>{t("content.text")}</span>
              </span>
            </h2>
          </div>
          <div className>
            <div className="card-grid-row mt-1 dashboard-content">
              <div className="secHeading">
                <div className="float-left">
                  <h3>
                    {t("common.popularTools")}
                  </h3>
                  <p>
                    {t("common.commonlyUsedTools")}
                  </p>
                </div>
                <Link className="float-right quickLink" to="/tools">
                  {t("common.allTools")}{" "}
                  <span className="count">{total}</span>
                </Link>
              </div>
              <div className="popTools conWrap" style={{ marginBottom: "0px" }}>
                {data.map((v, i) => (
                  <>
                    <div data-category="blog" className="card">
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        {v?.is_pro == "1" && (
                          <span class="newFeature">
                            {t("common.pro")}
                          </span>
                        )}
                        <div
                          onClick={() => handleFavUnfav(v?.id)}
                          className="ToolStarMain"
                        >
                          {v?.is_favrouite ? (
                            <FaStar className="SimpleStar" />
                          ) : (
                            <FaRegStar className="SimpleStar" />
                          )}
                        </div>
                        <div
                          onClick={() =>
                            navigate("/" + v.slug, { state: { v } })
                          }
                          className="card-wrap"
                        >
                          <div className="card-icon">
                            <div className="svgIcon">
                              {v.image != "" && <img src={v.image} />}
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="card-title h5">{v.title}</div>
                            <p className="card-text">{v.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>

          {Favroute.length > 0 && (
            <>
              <div className>
                <div className="card-grid-row mt-1 dashboard-content">
                  <div className="secHeading" style={{ marginBottom: "0px" }}>
                    <div className="float-left">
                      <h3>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                          }}
                        >
                          <FaStar className="SimpleStar" />{" "}
                          <span>
                            {t("common.favorites")}
                          </span>{" "}
                        </div>
                      </h3>
                    </div>
                  </div>
                  <div className="popTools conWrap">
                    {Favroute.map((v, i) => (
                      <>
                        <div data-category="blog" className="card">
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={() => handleFavUnfav(v?.id)}
                              className="ToolStarMain"
                            >
                              <FaStar className="SimpleStar" />
                            </div>
                            <div
                              onClick={() =>
                                navigate("/" + v.slug, { state: { v } })
                              }
                              className="card-wrap"
                            >
                              <div className="card-icon">
                                <div className="svgIcon">
                                  {v.image != "" && <img src={v.image} />}
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="card-title h5">{v.title}</div>
                                <p className="card-text">{v.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: "16px",
            pointerEvents: "none",
          }}
        />
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
}

export default Jobs;
