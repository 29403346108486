import React, { useEffect, useState } from "react";
import { doPost } from "./../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function VerifyEmail() {
  const navigate = useNavigate();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState("");

  useEffect(() => {
    const path = window.location.pathname;
    const lastSlashIndex = path.lastIndexOf("/");
    const slugFromUrl =
      lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";
    if (slugFromUrl != "") {
      setSlug(slugFromUrl);
      verify_user_account(slugFromUrl);
    } else {
      setSlug("not");
    }
  }, []);

  const verify_user_account = async (sllug) => {
    const payload = {
      slug: sllug,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "verify_user_email");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success(
          NotificationsToasts?.account_verified_successfully?.notification
        );
        navigate("/login");
      } else {
        setLoading(false);
        toast.error(data?.data);
      }
    }
  };

  return (
    <div className="flex_center_pages mt-5p">
      <div className="w50 margin_auto">
        <div className="card p40">
          <div className="login-container">
            Please wait we're verifying your account!
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
