export const urls = {
  // API: "https://dedevelopers.org/aiword/",
  // PHP_API: "https://dedevelopers.org/aiword/",
  // Image_Uri: "https://dedevelopers.org/aiword/",

  // API: "http://192.168.1.17/AI-Word-Backend/",
  // PHP_API: "http://192.168.1.17/AI-Word-Backend/",
  // Image_Uri: "http://192.168.1.17/AI-Word-Backend/",

  // API: "http://192.168.1.3/AI-Word-Backend/",
  // PHP_API: "http://192.168.1.3/AI-Word-Backend/",
  // Image_Uri: "http://192.168.1.3/AI-Word-Backend/",

  // API: "http://192.168.1.11/AI-Word-Backend/",
  // PHP_API: "http://192.168.1.11/AI-Word-Backend/",
  // Image_Uri: "http://192.168.1.11/AI-Word-Backend/",

  // prod
  // API: "https://aiword.ru/aiword/",
  // PHP_API: "https://aiword.ru/aiword/",
  // Image_Uri: "https://aiword.ru/aiword/",

  // local
  API: "http://localhost/back/",
  PHP_API: "http://localhost/back/",
  Image_Uri: "http://localhost/back/",
};
