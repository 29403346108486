// @ts-nocheck
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { doPost } from "./../../utils/apiCalls";
import { PaymentElement } from "@stripe/react-stripe-js";
import useAuth from "./../../hooks/useAuth";
import { useSelector } from "react-redux";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const paymentSuccessEntry = async (paymentIntent) => {
    const payment_data = {
      payment: paymentIntent,
      planId: props.plan.id,
      token: getLoggedObject().token,
    };
    const { isError, data } = await doPost(
      payment_data,
      "auth/successful_payment"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoggedObject(data?.data);
        toast.success(NotificationsToasts?.subscription_plan_upgraded?.notification);
        // navigate('/dashboard')
        window.location.href = "/dashboard";
      } else {
        toast.error(data?.data);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }
    setIsProcessing(true);
    const confirmPayment = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });
    console.log("type", confirmPayment);
    if (confirmPayment.paymentIntent) {
      paymentSuccessEntry(confirmPayment.paymentIntent);
    } else {
      // navigate('/subscriptions')
      toast.error(confirmPayment.error.message);
    }
    setIsProcessing(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isProcessing || !stripe || !elements}
        className="justify-content-md-center mt-3 btn btn-primary wd100"
      >
        {isProcessing ? "Processing ... " : "Pay now"}
      </button>
    </form>
  );
}
