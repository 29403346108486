import React, { useEffect, useState } from "react";
import SideBar from "../../base-components/SideBar";
import NavBarLOGIN from "./../../base-components/NavBar/navbar_login";
import { toast } from "react-toastify";
import { doPost } from "../../utils/apiCalls";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTokens } from "../../lib/Redux/slices/userslice";

const SuccessPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  useEffect(() => {
    const verifyPayment = async () => {
      let paymentId = localStorage.getItem("PaymentId");
      let plan_id = localStorage.getItem("plan_id");
      let body_data = {
        paymentId: paymentId,
        token: getLoggedObject().token,
        plan_id: plan_id,
      };
      try {
        const { isError, data } = await doPost(
          body_data,
          "auth/getPaymentDetails"
        );

        // console.log(data);

        if (isError) {
          toast.error(isError?.message);
          setIsLoading(false);
        } else if (data?.action === "success") {
          setIsLoading(false);
          toast.success(
            NotificationsToasts?.subscription_plan_upgraded?.notification
          );
          setLoggedObject(data?.data);
          setTimeout(() => {
            navigate("/pricing");
            dispatch(setTokens(0));
          }, 1000);
        } else {
          toast.error(data?.message);
          setTimeout(() => {
            navigate("/pricing");
          }, 1000);
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
        setIsLoading(false);
      }
    };
    verifyPayment();
  }, []);

  return (
    <div className="layout-container container-fluid">
      <NavBarLOGIN />
      <div className="layout-row row">
        <SideBar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="scrollable-body col-md-9 col-12"
        >
          {isLoading ? (
            <div className="loader-container">
              <h3>We are verifying your payment. Please wait...</h3>
              <div className="loader"></div>
            </div>
          ) : (
            <div>{/* Display content after verification */}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
