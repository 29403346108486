import ScrollToTop from "./base-components/ScrollToTop";
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import "./assets/css/app.css";
import "./assets/css/bootstrap.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import { Translator, Translate } from "react-auto-translate";
import ReduxProvider from "./lib/Redux/Reduxprovider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import './i18n';
import { Suspense } from 'react';
const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {

  const [dlang, setDLang] = useState("ru");
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  // example provider
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
        language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };

  useEffect(() => {
    const collapseValue = localStorage.getItem("i18nextLng");
    if (collapseValue === null) {
      setDLang("ru");
    } else {
      setDLang(collapseValue);
    }
  }, [dlang]);

  return (
    // <Translator
    //   cacheProvider={cacheProvider}
    //   from="en"
    //   to={dlang}
    //   googleApiKey="AIzaSyDtF7_jyO8M1GuQhSt1MHG4hlq1LCg_siU"
    // >
    <Suspense fallback="...loading">
      <GoogleOAuthProvider clientId="804494302712-ii2ki8iujtlnt4vblb5atorevdsf56e8.apps.googleusercontent.com">
        <BrowserRouter>
          <ReduxProvider>
            <Router />
            <ScrollToTop />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              className="toast_class"
            />
          </ReduxProvider>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </Suspense>
    // </Translator>
  );
};

root.render(<App />);

// import ScrollToTop from "./base-components/ScrollToTop";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
// import Router from "./router";
// import "./assets/css/app.css";
// import React, { useEffect, useRef } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <BrowserRouter>
//         {/* <div className="container"> */}
//             {/* <div className="wrapper"> */}
//                 <Router />
//                 <ScrollToTop />
//                 <ToastContainer
//                         position="top-right"
//                         autoClose={5000}
//                         hideProgressBar={false}
//                         newestOnTop={true}
//                         closeOnClick
//                         rtl={false}
//                         pauseOnFocusLoss
//                         draggable
//                         pauseOnHover
//                         theme="colored"
//                         className="toast_class"
//                     />
//             {/* </div> */}
//         {/* </div> */}
//   </BrowserRouter>
// );
