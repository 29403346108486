import React, { useEffect, useState, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import { doPost } from "../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Translate } from "react-auto-translate";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setFaroutesLists } from "../../lib/Redux/slices/webData";
import { useTranslation } from "react-i18next";

const Emails = ({ propdata }) => {
  const dispatch = useDispatch();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    if (propdata) {
      setData(propdata);
    } else {
      handle_content();
    }
  }, [propdata]);
  const handle_content = async () => {
    const lang = localStorage.getItem("i18nextLng");
    const payload = {
      token: getLoggedObject().token,
      slug: slugFromUrl,
      lang: lang,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_get_categories");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setLoading(false);
        dispatch(setFaroutesLists(data?.favourite_list));
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handleFavUnfav = async (id) => {
    const lang = localStorage.getItem("i18nextLng");
    const payload = {
      token: getLoggedObject().token,
      category_id: id,
      lang: lang,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/categoryFavouriteStore"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.status) {
        toast.info(data?.message);
        setLoading(false);
        handle_content();
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <>
      <div className="container-wrappe">
        <div className="main-layout container">
          <div className="header-txt">
            <h2>
              {t("common.careerTemplates")}
            </h2>
            <p className="text-left">
              {t("common.AIToolText")}
            </p>
          </div>
          <div className>
            <div className="card-grid-row mt-1">
              {data.map((v, i) => (
                <>
                  <div data-category="blog" className="card">
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      {v?.is_pro == "1" && (
                          <span class="newFeature">
                            {t("common.pro")}
                          </span>
                      )}
                      <div
                        onClick={() => handleFavUnfav(v?.id)}
                        className="ToolStarMain"
                      >
                        {v?.is_favrouite ? (
                          <FaStar className="SimpleStar" />
                        ) : (
                          <FaRegStar className="SimpleStar" />
                        )}
                      </div>
                      <div
                        onClick={() => navigate("/" + v.slug, { state: { v } })}
                        className="card-wrap"
                      >
                        <div className="card-icon">
                          <div className="svgIcon">
                            {v.image != "" && <img src={v.image} />}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="card-title h5">{v.title}</div>
                          <p className="card-text">{v.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: "16px",
            pointerEvents: "none",
          }}
        />
      </div>
    </>
  );
};

export default Emails;
