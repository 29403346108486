import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { Translator, Translate } from "react-auto-translate";
import { BehaviorSubject } from "rxjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const sharedService = new BehaviorSubject();

function Jobs() {
  const navigate = useNavigate();
  const { NotificationsToasts = null } = useSelector((state) => state.user);

  const { getLoggedObject } = useAuth();
  const [tab, setTab] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [percent, setPercent] = useState(0);
  const [uploadimg, setUploadImg] = useState("");

  const [labeldata, setLabelData] = useState([]);
  const [tokendata, setTokenData] = useState([]);

  useEffect(() => {
    get_user_info();
  }, []);

  const get_user_info = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_specific_user");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setName(data?.data.name);
        setEmail(data?.data.email);
        setData(data?.data);

        setLabelData(data?.data.lble);

        var total_words = data?.data.plan_words;
        var user_words = data?.data.words;

        // var final_percent = (data?.data.plan_words - data?.data.words) / (data?.data.words) * 100
        var final_percent = (user_words / total_words) * 100;
        var actual_percent = final_percent.toFixed(2);
        setPercent(actual_percent);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_update = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      name: name,
      image: uploadimg,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/update_user");
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        localStorage.setItem("AIWORDAUTH", JSON.stringify(data?.data));
        get_user_info();
        // sharedService.next(data?.data.profile_pic);
        toast.success(
          NotificationsToasts?.account_info_updated?.notification
        );
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const clickImage = () => {
    document.getElementById("fileInput").click();
  };

  const onFileChange = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        setUploadImg(event.target.result);
        const userImage = document.querySelector(".user_round_image");
        userImage.src = event.target.result;
      };
    } else {
      console.log("i am cancelled");
    }
  };

  const datachart = {
    labels: data?.lble,
    datasets: [
      {
        label: "Tokens",
        backgroundColor: "#2dc733",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 0,
        hoverBackgroundColor: "#2dc73370",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: data?.utokens,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div className="container-wrappe">
        {" "}
        <div className="main-layout profile page-layout">
          <div className="header-txt text-center">
            <h2>
              <Translate>Profile</Translate>
            </h2>
            <p>
              <Translate>
                Check your Account information, Billing details.
              </Translate>{" "}
            </p>
          </div>
          <div className="container justify-content-center">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="pb-2">
                  <Translate>Account Information</Translate>
                </h5>
                <div className="mb-5 card">
                  <div className="card-body">
                    <div className="wrapper bg-white">
                      <div className="py-4">
                        <div className="justify-content-md-center mb-3 row">
                          <div className="col-md-6">
                            <div>
                              <label className="form-label" htmlFor="firstName">
                                <Translate>Full Name</Translate>
                              </label>
                              <input
                                placeholder="Enter Full name"
                                required=""
                                type="text"
                                id="firstName"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <label className="form-label" htmlFor="email">
                                <Translate>Email Address</Translate>
                              </label>
                              <input
                                placeholder="Enter email"
                                type="email"
                                disabled={true}
                                id="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="justify-content-md-center row">
                          <div className="col-md-12">
                            <div className="wd100">
                              <label className="form-label" htmlFor="email">
                                <Translate>Profile Picture</Translate>
                              </label>
                              <div>
                                <img
                                  src={data?.image}
                                  className="user_round_image"
                                  onClick={(e) => clickImage(e)}
                                  style={{ cursor: "pointer" }}
                                />
                                <input
                                  type="file"
                                  id="fileInput"
                                  className="form-control"
                                  name="image"
                                  onChange={(e) => onFileChange(e)}
                                  style={{ display: "none" }}
                                />
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="justify-content-md-center mt-3 btn btn-primary"
                              onClick={() => do_update()}
                            >
                              <Translate>Update</Translate>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="pb-2">
                  <Translate>Billing Details</Translate>
                </h5>
                <div className="mb-5 card">
                  <div className="card-body">
                    <div className="wrapper bg-white billing">
                      <div className="py-4">
                        <div className="subHead">
                          <h6>
                            <Translate>Active Plan</Translate>{" "}
                          </h6>
                          <p>
                            <Translate>
                              Your current active plan details
                            </Translate>
                          </p>
                        </div>
                        <div className="row">
                          <div className="col">
                            <p>
                              <strong>
                                <Translate>Plan:</Translate>
                              </strong>
                              <br /> <Translate>{data.plan_title}</Translate>
                            </p>
                          </div>
                          <div className="col">
                            {" "}
                            <p>
                              <strong>
                                <Translate>Plan status:</Translate>
                              </strong>
                              <br /> <Translate>Active</Translate>
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              <strong>
                                <Translate>Start date:</Translate>
                              </strong>
                              <br />{" "}
                              <Translate>{data.plan_start_date}</Translate>
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              <strong>
                                <Translate>Amount paid:</Translate>
                              </strong>
                              <br />₽{data.amount_paid}
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              <strong>
                                <Translate>Next renewal:</Translate>
                              </strong>
                              <br />{" "}
                              <Translate>{data.plan_expiry_date}</Translate>
                            </p>
                          </div>
                        </div>
                        {/* <div className="row"><div className="col py-4"> 
                      <button type="button" className="btn btn-outline-primary btn-sm">Update Billing Details</button>
                      <p className="mt-3 mb-0">Current Plan, Payment Method, Billing Information, Invoice History</p>
                      <div className="float-right loaderStyle"></div>
                      </div></div> */}
                      </div>

                      <div className="py-4">
                        <div className="subHead">
                          {data.tiral == 1 && (
                            <>
                              <h6 className="text-capitalize">
                                <Translate>Free trial usage</Translate>
                              </h6>
                              <p>
                                <Translate>
                                  You are currently on free trial.
                                </Translate>{" "}
                              </p>
                            </>
                          )}
                          {data.tiral == 0 && (
                            <>
                              <h6 className="text-capitalize">
                                <Translate>{data.plan_title}</Translate>
                              </h6>
                              {data.plan_words == "-1" && (
                                <p>
                                  <Translate>
                                    You are currently on unlimited plan.
                                  </Translate>
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="float-right">
                          <button
                            type="button"
                            className="btn btn-primary float-right px-3 btn-sm"
                            onClick={() => navigate("/pricing")}
                          >
                            <Translate>Upgrade</Translate>
                          </button>
                        </div>
                        {data.plan_words == "-1" ? (
                          <></>
                        ) : (
                          <div className="row">
                            <div className="col">
                              <p className="mb-2">
                                <Translate>
                                  Check the available words on your plan.
                                </Translate>
                              </p>
                              <div
                                className="progress"
                                style={{ borderRadius: "10px" }}
                              >
                                <div
                                  role="progressbar"
                                  className="progress-bar"
                                  aria-valuenow={percent}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                  style={{
                                    width: percent + "%",
                                    height: "1.5rem",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {percent}%
                                </div>
                              </div>
                              <p className="text-right m-0 pb-2">
                                {data.words == 0 ? data.words : data.words} /{" "}
                                {data.plan_words == 0
                                  ? data.plan_words
                                  : data.plan_words}{" "}
                                <Translate>Words</Translate>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="pb-2">
                  <Translate>Usage Stats</Translate>
                </h5>
                <div style={{ width: "100%" }}>
                  <Bar data={datachart} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: 16,
            pointerEvents: "none",
          }}
        />
      </div>
    </>
  );
}

export default Jobs;
