import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost, OPENAPI, CountWords, handleCopyClick } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DELETE from './../../assets/images/delete.svg'
import {Translator, Translate} from 'react-auto-translate';
import COPYICON from './../../assets/images/copy_icon.svg'
import DELICON from './../../assets/images/del_icon.svg'
import { useTranslation } from "react-i18next";

const Common = ({ onSelectionChange, onSelectionVariant, onSelectionTone, onSelectionCreativity }) => {
    const [language, setLanguage] = useState('English')
    const [variant, setVariant] = useState(1);
    const [tone, setTone] = useState('Formal');
    const [creativity, setCreativity] =  useState('0.75')
    const { t } = useTranslation();
    const path = window.location.pathname;
    const lastSlashIndex = path.lastIndexOf('/');
    const slugFromUrl = lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : '';

    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        setLanguage(selectedValue);
        onSelectionChange(selectedValue); // Pass the selected value to the parent component
    };

    const handleDropdownVariant = (event) => {
        const selectedValue = event.target.value;
        setVariant(selectedValue);
        onSelectionVariant(selectedValue); // Pass the selected value to the parent component
    };

    const handleDropdownTone = (event) => {
        const selectedValue = event.target.value;
        setTone(selectedValue);
        onSelectionTone(selectedValue); // Pass the selected value to the parent component
    };

    const handleDropdownCreativity = (event) => {
        const selectedValue = event.target.value;
        setCreativity(selectedValue);
        onSelectionCreativity(selectedValue); // Pass the selected value to the parent component
    };


  return (
    <>
    {/* <div>
      <select onChange={handleDropdownChange} value={selectedOption}>
        <option value="">Select an option</option>
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
      </select>
    </div> */}
{
        (
            slugFromUrl != "text-summarizer" 
            
            // && slugFromUrl != 'email-subject-line'
            // && slugFromUrl != 'email-subject-line'
            // && slugFromUrl != 'email-subject-line'
            // && slugFromUrl != 'email-subject-line'
            
        ) &&
    <div className="mt-3 mb-3 row">
        <div className="col">
            <label className="form-label">{t("common.language")}</label>
            <select
            name="language"
            className="form-control form-select"
            id="language"
            value={language}
            onChange={(e)=>handleDropdownChange(e)}
            >
            <option value="Russian">Russian</option>
            <option value="English (US)">English (US)</option>
            <option value="English (UK)">English (UK)</option>
            <option value="French">French</option>
            <option value="Spanish">Spanish</option>
            <option value="German">German</option>
            <option value="Italian">Italian</option>
            <option value="Dutch">Dutch</option>
            <option value="Arabic">Arabic</option>
            <option value="Portuguese">Portuguese</option>
            <option value="Portuguese (BR)">Portuguese (BR)</option>
            <option value="Swedish">Swedish</option>
            <option value="Norwegian">Norwegian</option>
            <option value="Danish">Danish</option>
            <option value="Finnish">Finnish</option>
            <option value="Romanian">Romanian</option>
            <option value="Czech">Czech</option>
            <option value="Slovak">Slovak</option>
            <option value="Slovenian">Slovenian</option>
            <option value="Hungarian">Hungarian</option>
            <option value="Hebrew">Hebrew</option>
            <option value="Polish">Polish</option>
            <option value="Turkish">Turkish</option>
            
            <option value="Hindi">Hindi</option>
            <option value="Thai">Thai</option>
            <option value="Japonese">Japonese</option>
            <option value="Chinese (Simplified)">
                Chinese (Simplified)
            </option>
            <option value="Korean">Korean</option>
            <option value="Indonesian">Indonesian</option>
            <option value="Greek">Greek</option>
            <option value="Bulgarian">Bulgarian</option>
            <option value="Ukrainian">Ukrainian</option>
            <option value="Estonian">Estonian</option>
            <option value="Latvian">Latvian</option>
            <option value="Lithuanian">Lithuanian</option>
            <option value="Croatian">Croatian</option>
            <option value="Serbian">Serbian</option>
            <option value="Bosnian">Bosnian</option>
            <option value="Macedonian">Macedonian</option>
            <option value="Albanian">Albanian</option>
            <option value="Vietnamese">Vietnamese</option>
            </select>
        </div>
        <div className="col">
            <label className="form-label">{t("common.toneOfVoice")}</label>
            <select
            aria-label="Default select example"
            name="tone"
            className="form-control form-select"
            value={tone}
            onChange={(e)=>handleDropdownTone(e)}
            >
            <option value={'Formal'}>{t("common.formal")}</option>
            <option value={'Professional'}>{t("common.professional")}</option>
            <option value={'Informative'}>{t("common.informative")}</option>
            <option value={'Friendly'}>{t("common.friendly")}</option>
            <option value={'Bold'}>{t("common.bold")}</option>
            <option value={'Witty'}>{t("common.witty")}</option>
            <option value={'Adventurous'}>{t("common.adventurous")}</option>
            <option value={'Persuasive'}>{t("common.persuasive")}</option>
            </select>
        </div>
    </div>
}

    {
        (
            slugFromUrl != "sentence-rewriter" 
            && slugFromUrl != 'article-rewriter' 
            && slugFromUrl != "sentence-expander" 
            && slugFromUrl != 'paragraph-points'
            && slugFromUrl != 'paraphrase'
            && slugFromUrl != 'cold-email'
            && slugFromUrl != 'welcome-email'
            && slugFromUrl != 'cancellation-email'
            && slugFromUrl != 'follow-up-email'
            && slugFromUrl != 'email-subject-line'
            && slugFromUrl != 'thank-you-note'
            && slugFromUrl != 'name-generator'
            // && slugFromUrl != 'email-subject-line'
            // && slugFromUrl != 'email-subject-line'
            // && slugFromUrl != 'email-subject-line'
            // && slugFromUrl != 'email-subject-line'
            // && slugFromUrl != 'email-subject-line'
            
        ) &&
    
        <div className="mt-3 mb-3 row">
            <div className="col">
                <label className="form-label">{t("common.variants")}</label>
                <select
                aria-label="Number of Variants"
                name="variant"
                className="form-control form-select"
                value={variant}
                onChange={(e)=>handleDropdownVariant(e)}
                >
                <option value={1}>1 {t("common.variant")}</option>
                <option value={2}>2 {t("common.variants")}</option>
                <option value={3}>3 {t("common.variants")}</option>
                <option value={4}>4 {t("common.variants")}</option>
                <option value={5}>5 {t("common.variants")}</option>
                <option value={6}>6 {t("common.variants")}</option>
                </select>
            </div>
            <div className="col">
                <label className="form-label">{t("common.creativity")}</label>
                <select
                aria-label="creativity Level"
                name="creativity"
                className="form-control form-select"
                value={creativity}
                onChange={(e)=>handleDropdownCreativity(e)}
                >
                <option value="0.75">{t("common.regular")}</option>
                <option value="0.35">{t("common.low")}</option>
                <option value="0.65">{t("common.medium")}</option>
                <option value={1}>{t("common.high")}</option>
                </select>
            </div>
        </div>
    }
    </>
  );
};

export default Common;
