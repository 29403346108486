import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import {
  doPost,
  OPENAPI,
  CountWords,
  handleCopyClick,
  handleSaveContent,
  do_update_token,
} from "./../../utils/apiCalls";
import { Translate } from "react-auto-translate";
import COPYICON from "./../../assets/images/copy_icon.svg";
import DELICON from "./../../assets/images/del_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTokens } from "../../lib/Redux/slices/userslice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ResponseEditor = ({
  loading,
  responseAI,
  contentCategory,
  image_data,
}) => {
  const { t } = useTranslation(); 
  const dispatch = useDispatch();
  const { Tokenvalue = 0 } = useSelector((state) => state.web);
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const [newresponseAI, setNewResponseAI] = useState([]);
  const { getLoggedObject } = useAuth();
  const do_update_response = (v) => {
    if (responseAI.length > 1) {
      const new_content = v;

      return new_content;
    } else {
      return v;
    }
  };

  useEffect(() => {
    setNewResponseAI(responseAI);
  }, [responseAI]);

  useEffect(() => {
    const fetch = async () => {
      let last = responseAI[responseAI.length - 1];
      let length = CountWords(last);
      const response = await do_update_token(length, getLoggedObject()?.token , NotificationsToasts);
      if (response) {
        dispatch(setTokens(response));
      }
    };
    fetch();
  }, [responseAI]);

  const do_update_ai_content = async (ind, event) => {
    const updatedResponseAI = [...newresponseAI];
    updatedResponseAI[ind] = event;
    setNewResponseAI(updatedResponseAI);
  };

  // React Quill Settings....

  const [editorHtml, setEditorHtml] = useState("");

  const [selectedText, setSelectedText] = useState("");

  const handleSelectionChange = (range, source, editor) => {
    if (range && range.length > 0) {
      const selectedHtml = editor.getContents(range.index, range.length);
      setSelectedText(selectedHtml);
    } else {
      setSelectedText("");
    }
  };

  // Rewrite Content

  const [RewriteLoading, setRewriteLoading] = useState(false);
  const RewriteContent = async (ind) => {
    setRewriteLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = 1;
    const language = localStorage.getItem("i18nextLng");

    const textToRewrite =
      selectedText?.ops?.map((op) => op.insert).join("") || "";
    const Rewrite = `Rewrite ${textToRewrite}`;

    const payload = [
      { role: "user", content: Rewrite },
      {
        role: "system",
        content:
          "Response should be in " +
          (language === "en" ? "English" : "Russian"),
      },
    ];

    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );

    if (response_ai === "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting_api?.notification
      );
      setRewriteLoading(false);
      return;
    }
    if (response_ai === "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      setRewriteLoading(false);
      return;
    }

    const final_response = response_ai.choices[0].message.content;

    let updatedHtml = editorHtml;
    if (final_response) {
      updatedHtml = updatedHtml.replace(textToRewrite, final_response);
    }
    setEditorHtml(updatedHtml);
    setNewResponseAI((prev) => {
      let newArr = [...prev];
      newArr[ind] = updatedHtml;
      return newArr;
    });

    const Words = CountWords(final_response);

    UpdateTokens(Words);

    setRewriteLoading(false);
  };

  // Extend Content

  const ExtendContent = async (ind) => {
    setRewriteLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = 1;
    const language = localStorage.getItem("i18nextLng");

    const textToExtend =
      selectedText?.ops?.map((op) => op.insert).join("") || "";

    const Extend = `Explain it more ${textToExtend}`;

    const payload = [
      { role: "user", content: Extend },
      {
        role: "system",
        content:
          "Response should be in " +
          (language === "en" ? "English" : "Russian"),
      },
    ];

    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );

    if (response_ai === "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting_api?.notification
      );
      return;
    }
    if (response_ai === "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }

    const final_response = response_ai.choices[0].message.content;

    const Words = CountWords(final_response);

    UpdateTokens(Words);

    let updatedHtml = editorHtml;
    if (final_response) {
      updatedHtml = updatedHtml.replace(textToExtend, final_response);
    }
    setEditorHtml(updatedHtml);
    setNewResponseAI((prev) => {
      let newArr = [...prev];
      newArr[ind] = updatedHtml;
      return newArr;
    });

    setRewriteLoading(false);
  };

  const UpdateTokens = async (used) => {
    const response = await do_update_token(used, getLoggedObject()?.token , NotificationsToasts);
    if (response) {
      dispatch(setTokens(response));
    }
  };

  const modules = {
    toolbar: [
      // Define your toolbar options here
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <div className="card tool_car_right_response">
        <div className="ai-board-body card-body">
          {loading && (
            <div className="genMoreLoad">
              <div className="loaderWrap">
                <strong>
                  {t("common.generatingContent")}...
                </strong>
                <p>
                  {t("common.pleaseWaitAFewSeconds")}
                </p>
                <div className="loader"></div>
              </div>
            </div>
          )}
          {!loading && newresponseAI == "" && (
            <div className="noResults">
              <h5>
                <i>🚀</i> <span>{t("common.getStartNow")}...</span>
              </h5>
              <p>
                {t("common.generateHighQualityContent")}
              </p>
            </div>
          )}

          {newresponseAI != "" && (
            <>
              {RewriteLoading && (
                <div className="genMoreLoad">
                  <div className="loaderWrap">
                    <strong>
                      {t("common.generatingContent")}...
                    </strong>
                    <p>
                      {t("common.pleaseWaitAFewSeconds")}
                    </p>
                    <div className="loader"></div>
                  </div>
                </div>
              )}
              {!RewriteLoading &&
                newresponseAI.map((v, i) => (
                  <>
                    <div
                      className="ai-section height-align text-center"
                      key={"response_" + i}
                    >
                      <div className="ai-content">
                        <div>
                          <ReactQuill
                            defaultValue={v.replaceAll("\n", "<br />")}
                            onChange={setEditorHtml}
                            onChangeSelection={handleSelectionChange}
                          />
                        </div>
                        <span className="index">{i + 1}</span>
                        <div className="btn-group-wrap text-right">
                          <div className="float-left display-flex">
                            {image_data == 1 ? (
                              <></>
                            ) : (
                              <span className="wordCount float-left">
                                <span>{t("words")}</span>: {CountWords(v)}
                              </span>
                            )}
                          </div>
                          <div style={{ pointerEvents: "auto" }}>
                            <button
                              onClick={() => handleCopyClick(v , NotificationsToasts)}
                              type="button"
                              id={0}
                              className="btn btn-outline-secondary btn-sm"
                            >
                              <img src={COPYICON} />{" "}
                              <span>
                                {t("common.copy")} {image_data == 1 ? "Image Url" : ""}
                              </span>{" "}
                            </button>
                            {image_data == 1 ? (
                              <></>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  id={0}
                                  className="ml-1  btn btn-outline-secondary btn-sm"
                                  onClick={() =>
                                    handleSaveContent(
                                      v,
                                      contentCategory,
                                      CountWords(v),
                                      getLoggedObject()?.token,
                                      NotificationsToasts
                                    )
                                  }
                                >
                                  <img src={DELICON} />{" "}
                                  <span>{t("common.save")}</span>
                                </button>
                                <div class="wktooltip">
                                  <button
                                    disabled={
                                      selectedText === "" ? true : false
                                    }
                                    type="button"
                                    onClick={() => RewriteContent(i)}
                                    class="ml-auto mr-auto rewrite btn btn-outline-secondary btn-sm"
                                  >
                                    <svg
                                      version="1.1"
                                      id="Uploaded to svgrepo.com"
                                      xmlns="http://www.w3.org/2000/svg"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 32 32"
                                    >
                                      <path
                                        d="M28,24v4H4V8h10.071l-3.657-3.654l2.828-2.826l8.485,8.485l-8.485,8.484l-2.828-2.83L14.071,12H8v12H28z"
                                        fill="#000000"
                                      ></path>
                                    </svg>{" "}
                                    <span>{t("common.rewrite")}</span>
                                  </button>
                                </div>
                                <div className="wktooltip">
                                  <button
                                    type="button"
                                    className="ml-1 mr-auto expand btn btn-outline-secondary btn-sm"
                                    disabled={
                                      selectedText === "" ? true : false
                                    }
                                    onClick={() => ExtendContent(i)}
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="16px"
                                      height="16px"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g>
                                        <g>
                                          <rect
                                            transform="rotate(180 12 12)"
                                            opacity={0}
                                          />
                                          <path
                                            fill="#000000"
                                            d="M20 5a1 1 0 0 0-1-1h-5a1 1 0 0 0 0 2h2.57l-3.28 3.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L18 7.42V10a1 1 0 0 0 1 1 1 1 0 0 0 1-1z"
                                          />
                                          <path
                                            fill="#000000"
                                            d="M10.71 13.29a1 1 0 0 0-1.42 0L6 16.57V14a1 1 0 0 0-1-1 1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2H7.42l3.29-3.29a1 1 0 0 0 0-1.42z"
                                          />
                                        </g>
                                      </g>
                                    </svg>{" "}
                                    <span>{t("common.extend")}</span>
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ResponseEditor;
