import React, { useEffect, useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { toast } from "react-toastify";
import SideBar from "./../../base-components/SideBar";
import NavBarLOGIN from "./../../base-components/NavBar/navbar_login";
import { Translate } from "react-auto-translate";
import BLOGDATA from "./blog-content";
import WEBDATA from "./website_seo";
import SOCIALDATA from "./SocialMedia";
import MARKDATA from "./Marketing";
import MOREATA from "./Other";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Jobs() {
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const { getLoggedObject } = useAuth();
  const [all, setAll] = useState(-1);

  const [total, setTotal] = useState(0);
  const [total1, setTotal1] = useState(0);
  const [total2, setTotal2] = useState(0);
  const [total3, setTotal3] = useState(0);
  const [total4, setTotal4] = useState(0);
  const [total5, setTotal5] = useState(0);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);

  const [loading, setLoading] = useState(false);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";
  const { i18n } = useTranslation();
  const { t } = useTranslation()
  const currentLanguage = i18n.language;

  useEffect(() => {
    handle_content();
  }, []);
  const handle_content = async () => {
    // const lang = localStorage.getItem("i18nextLng");
    const payload = {
      slug: 1,
      lang: currentLanguage,
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_all_categories_count"
    );
    if (isError) {
      toast.error(NotificationsToasts?.server_error?.notification);
      setLoading(false);
    } else {
      if (data.action == "success") {
        setTotal(data?.total);
        setTotal1(data?.total1);
        setTotal2(data?.total2);
        setTotal3(data?.total3);
        setTotal4(data?.total4);
        setTotal5(data?.total5);

        setData1(data?.data1);
        setData2(data?.data2);
        setData3(data?.data3);
        setData4(data?.data4);
        setData5(data?.data5);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <>
      <div className="layout-container container-fluid">
        <style>{`.facebookicon svg path { fill: #f00 !important; } .facebookicon svg path:last-child { fill: #fff !important; } .header-txt {display: none}`}</style>
        <NavBarLOGIN />
        <div className="layout-row row">
          <SideBar />
          <div className="scrollable-body col-md-9 col-12">
            <div className="container-wrappe">
              <div className="main-layout container">
                <div className="header-txt">
                  <h2>
                    {t("common.tools")}
                  </h2>
                </div>
                <div className="">
                  <div className="card-sort-btns mb-4">
                    <button
                      type="button"
                      title="all"
                      className={`${
                        all == -1 ? "active" : ""
                      } btn btn-outline-primary btn-sm`}
                      onClick={() => setAll(-1)}
                    >
                      <span>{t("common.all")}</span>{" "}
                      <span className="badge badge-light" title="all">
                        {total}
                      </span>
                    </button>
                    <button
                      type="button"
                      title="blog"
                      className={`${
                        all == 1 ? "active" : ""
                      } btn btn-outline-primary btn-sm`}
                      onClick={() => setAll(1)}
                    >
                      <span>{t("common.blogContent")}</span>
                      <span className="badge badge-light" title="blog">
                        {total1}
                      </span>
                    </button>
                    <button
                      type="button"
                      title="website"
                      className={`${
                        all == 2 ? "active" : ""
                      } btn btn-outline-primary btn-sm`}
                      onClick={() => setAll(2)}
                    >
                      <span>{t("common.websiteSEO")}</span>
                      <span className="badge badge-light" title="website">
                        {total2}
                      </span>
                    </button>
                    <button
                      type="button"
                      title="socialMedia"
                      className={`${
                        all == 3 ? "active" : ""
                      } btn btn-outline-primary btn-sm`}
                      onClick={() => setAll(3)}
                    >
                      <span>{t("common.socialMedia")}</span>
                      <span className="badge badge-light" title="socialMedia">
                        {total3}
                      </span>
                    </button>
                    <button
                      type="button"
                      title="ads"
                      className={`${
                        all == 4 ? "active" : ""
                      } btn btn-outline-primary btn-sm`}
                      onClick={() => setAll(4)}
                    >
                      <span>{t("common.marketing")}</span>
                      <span className="badge badge-light" title="ads">
                        {total4}
                      </span>
                    </button>
                    <button
                      type="button"
                      title="more"
                      className={`${
                        all == 5 ? "active" : ""
                      } btn btn-outline-primary btn-sm`}
                      onClick={() => setAll(5)}
                    >
                      <span>{t("common.more")}..</span>
                      <span className="badge badge-light" title="more">
                        {total5}
                      </span>
                    </button>
                  </div>
                  <div className="card-grid-row mt-1">
                    {(all == -1 || all == 1) && (
                      <>
                        <div className="secHeading">
                          <h3>
                            {t("common.blog")}
                          </h3>
                        </div>
                        <BLOGDATA propdata={data1} />
                      </>
                    )}

                    {(all == -1 || all == 2) && (
                      <>
                        <div className="secHeading">
                          <h3>
                            {t("common.website")}
                          </h3>
                        </div>
                        <WEBDATA propdata2={data2} />
                      </>
                    )}

                    {(all == -1 || all == 3) && (
                      <>
                        <div className="secHeading">
                          <h3>
                            {t("common.socialMedia")}
                          </h3>
                        </div>
                        <SOCIALDATA propdata={data3} />
                      </>
                    )}

                    {(all == -1 || all == 4) && (
                      <>
                        <div className="secHeading">
                          <h3>
                            {t("common.marketing")}
                          </h3>
                        </div>
                        <MARKDATA propdata={data4} />
                      </>
                    )}
                    {(all == -1 || all == 5) && (
                      <>
                        <div className="secHeading">
                          <h3>
                            {t("common.more")}
                          </h3>
                        </div>
                        <MOREATA propdata={data5} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: "fixed",
                  zIndex: 9999,
                  inset: 16,
                  pointerEvents: "none",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
