import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DELETE from "./../../assets/images/delete.svg";

import {
  doPost,
  OPENAPI,
  CountWords,
  handleCopyClick,
} from "./../../utils/apiCalls";
import { Translator, Translate } from "react-auto-translate";
import Common from "./common";
import ResponseDiv from "./Response";
import ResponseEditor from "./ResponseEditor";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Inner({ data = [] }) {
  const { state } = useLocation();
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const [blogimage, setBlogImage] = useState(state?.v.image);

  const [option, setOption] = useState(false);
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [brandname, setBrandName] = useState("");
  const [description, setDescription] = useState(
    data?.data[1]?.textfield || ""
  );

  const [language, setLanguage] = useState("Russian");
  const [variant, setVariant] = useState(1);
  const [tone, setTone] = useState("Formal");
  const [creativity, setCreativity] = useState("0.75");
  const [responseAI, setResponseAI] = useState([]);
  const [type, setType] = useState("reply_email");

  const [selectedValue, setSelectedValue] = useState("");

  const handleDropdownChange = (selectedValue) => {
    setLanguage(selectedValue);
  };
  const handleDropdownVariant = (selectedValue) => {
    setVariant(selectedValue);
  };
  const handleDropdownTone = (selectedValue) => {
    setTone(selectedValue);
  };
  const handleDropdownCreativity = (selectedValue) => {
    setCreativity(selectedValue);
  };

  const do_loop_variants = async () => {
    setLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = creativity;

    const content_submit =
      "write the best convincing reply email in in clear and spacing with professional format where user wants to create is (" +
      brandname +
      ") and key points to cover is (" +
      description +
      ") ";
    const payload = [
      { role: "user", content: content_submit },
      { role: "system", content: "Response should be in " + language },
      { role: "system", content: `You should use a ${tone} tone.` },
    ];
    // console.log(payload);
    // return;
    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );
    setLoading(false);
    if (response_ai == "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting?.notification
      );
      return;
    }
    if (response_ai == "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }
    const final_response = response_ai.choices[0].message.content;
    var lines = [final_response];
    setResponseAI((prevResponse) => [...prevResponse, ...lines]);
  };

  const do_make_action = async () => {
    if (description == "") {
      toast.error(NotificationsToasts?.write_key_points_to_cover?.notification);
      return;
    }

    var user_variants = variant;
    for (let i = 0; i < user_variants; i++) {
      do_loop_variants();
    }
  };

  return (
    <>
      <div className="container-wrappe">
        <div className="main-layout">
          <div className="flexyWrap">
            <section className="tool-panel flexy-template">
              <div className="row">
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <section className="tool-header">
                        <div>
                          <div className="svgIcon">
                            <img src={blogimage} />
                          </div>
                          <div>
                            <h2>{data?.tool_title}</h2>
                            <p>{data?.tool_description}</p>
                          </div>
                        </div>
                      </section>

                      <form className="">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="productFeatures"
                          >
                            {t("common.keyPointsToCover")}
                          </label>{" "}
                          <textarea
                            maxLength={3000}
                            placeholder={
                              data?.data?.length > 0 &&
                              data?.data[1]?.placeholder
                            }
                            name="productFeatures"
                            required=""
                            id="productFeatures"
                            className="form-control"
                            style={{ height: 277 }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                        <small className="text-mute infoTxt">
                          {t("common.replaceSquareBrackets")}
                        </small>
                        <div className="advanceOptions">
                          <div className="accordion">
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button
                                  type="button"
                                  aria-expanded="false"
                                  className="accordion-button collapsed"
                                  onClick={() => setOption(!option)}
                                >
                                  {t("common.advancedOptions")}
                                </button>
                              </h2>
                              {option && (
                                <div className="accordion-collapse ">
                                  <div className="accordion-body">
                                    <Common
                                      onSelectionChange={handleDropdownChange}
                                      onSelectionVariant={handleDropdownVariant}
                                      onSelectionTone={handleDropdownTone}
                                      onSelectionCreativity={
                                        handleDropdownCreativity
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          disabled={loading}
                          className="mt-3 w-100 btn btn-primary"
                          onClick={() => do_make_action()}
                        >
                          {loading ? (
                            <div className="flex">
                              <span>
                                {t("common.loading")}...
                              </span>
                            </div>
                          ) : (
                            <span>{t("common.generateContent")}</span>
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="ai-board col-md-7 col">
                  <ResponseEditor
                    loading={loading}
                    responseAI={responseAI}
                    contentCategory={type}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            zIndex: 9999,
            inset: 16,
            pointerEvents: "none",
          }}
        />
      </div>
    </>
  );
}

export default Inner;
