import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DELETE from './../../assets/images/delete.svg'
import { useTranslation } from "react-i18next";

function Inner() {
const [selectedfolder, setSelectedFolder] = useState(0);
const { t } = useTranslation()
  return (
    <>
      <div className="container-wrappe">
        <div className="main-layout">
            <div className="keywordResearch container">
            <div className="search-container mb-5">
                <section className="header-txt m-0 p-0 text-center">
                <h2 className="text-center">{t("common.keywordResearch")}</h2>
                <p>
                    {t("common.getAccurateAndRealTime")}
                </p>
                </section>
                <div className="search-row mt-3 mb-4 row">
                <div className="keywordSearchForm">
                    <form className="">
                    <div className="justify-content-md-center row">
                        <div className="col">
                        <div>
                            <input
                            placeholder="Eg: Green tea"
                            type="text"
                            className="search-input form-control"
                            defaultValue=""
                            />
                        </div>
                        </div>
                        <div className="col">
                        <select className="select-input form-control form-select form-control">
                            <option value="">{t("common.selectCountry")}</option>
                            <option value=" ">{t("common.global")}</option>
                            <option value="us">{t("common.unitedStates")}</option>
                            <option value="gb">{t("common.unitedKingdom")}</option>
                            <option value="in">{t("common.india")}</option>
                            <option value="au">{t("common.australia")}</option>
                            <option value="ca">{t("common.canada")}</option>
                            <option value="nz">{t("common.newZealand")}</option>
                            <option value="za">{t("common.southAfrica")}</option>
                        </select>
                        </div>
                        <div className="col">
                        <button type="submit" disabled="" className="btn btn-primary">
                            {t("common.search")}
                        </button>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            <div
            style={{
                position: "fixed",
                zIndex: 9999,
                inset: 16,
                pointerEvents: "none"
            }}
            />
        </div>
        </div>

    </>
);
}

export default Inner;
