import React, { useEffect, useState } from "react";
import NavBarLOGIN from "../../base-components/NavBar/navbar_login";
import SideBar from "../../base-components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import {
  CountWords,
  OPENAPI,
  doPost,
  do_update_token,
  handleCopyClick,
  handleSaveContent,
} from "../../utils/apiCalls";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { setTokens } from "../../lib/Redux/slices/userslice";
import { Translate } from "react-auto-translate";
import { useTranslation } from "react-i18next";

const Chat = () => {
  const dispatch = useDispatch();
  const { getLoggedObject } = useAuth();
  const [Loading, setLoading] = useState();
  const [Chats, setChats] = useState([]);
  const [question, setQuestion] = useState("");
  const { NotificationsToasts = null } = useSelector((state) => state.user);
  const { t } = useTranslation(); 

  const [dlang, setDLang] = useState("ru");
  const languagelocal = localStorage.getItem("i18nextLng");

  const show_lang = languagelocal == "en" ? "en" : "ru";
  // setDLang(show_lang)

  const SubmittChat = async (Again = "", lastIndex = 0, lastquestion = "") => {
    if (Again == "") {
      if (question === "") {
        return toast.error(NotificationsToasts?.type_message?.notification);
      }
    }
    setLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = 1;
    const language = localStorage.getItem("i18nextLng");
    const payload = [
      { role: "user", content: Again === "Again" ? lastquestion : question },
      {
        role: "system",
        content:
          "Response  language is " +
          (language === "en" ? "English" : "Russian"),
      },
    ];

    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );
    setLoading(false);

    if (response_ai === "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting_api?.notification
      );
      return;
    }
    if (response_ai === "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }

    const final_response = response_ai.choices[0].message.content;
    let Words = CountWords(final_response);
    UpdateTokens(Words);

    if (Again === "Again") {
      const newChat = {
        userMessage: lastquestion,
        response: final_response,
      };
      setChats((prevChats) => {
        const updatedChats = [...prevChats];
        updatedChats[lastIndex] = newChat;
        return updatedChats;
      });
    } else {
      const newChat = {
        userMessage: question,
        response: final_response,
      };
      setChats((prevChats) => [...prevChats, newChat]);
    }

    setQuestion("");
  };

  const handleTryAgain = () => {
    if (Chats.length > 0) {
      const lastIndex = Chats.length - 1;
      const lastquestion = Chats[lastIndex]?.userMessage;
      SubmittChat("Again", lastIndex, lastquestion);
    } else {
      toast.error(NotificationsToasts?.chat_first?.notification);
    }
  };

  // React Quill Settings....

  const [editorHtml, setEditorHtml] = useState("");
  const [selectedText, setSelectedText] = useState("");

  const handleSelectionChange = (range, source, editor) => {
    if (range && range.length > 0) {
      const selectedHtml = editor.getContents(range.index, range.length);
      setSelectedText(selectedHtml);
    } else {
      setSelectedText("");
    }
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const [RewriteLoading, setRewriteLoading] = useState(false);

  const RewriteContent = async () => {
    setRewriteLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = 1;
    const language = localStorage.getItem("i18nextLng");

    const textToRewrite =
      selectedText?.ops?.map((op) => op.insert).join("") || "";

    const Rewrite = `Rewrite ${textToRewrite}`;

    const payload = [
      { role: "user", content: Rewrite },
      {
        role: "system",
        content:
          "Response should be in " +
          (language === "en" ? "English" : "Russian"),
      },
    ];

    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );

    if (response_ai === "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting_api?.notification
      );
      return;
    }
    if (response_ai === "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }

    const final_response = response_ai.choices[0].message.content;
    const Words = CountWords(final_response);

    UpdateTokens(Words);

    if (final_response) {
      const updatedHtml = editorHtml.replace(textToRewrite, final_response);
      setEditorHtml(updatedHtml);
    }

    setRewriteLoading(false);
  };

  const ExtendContent = async () => {
    setRewriteLoading(true);
    const user_token = getLoggedObject()?.token;
    const max_tokens = 10;
    const temperature = 1;
    const language = localStorage.getItem("i18nextLng");

    const textToExtend =
      selectedText?.ops?.map((op) => op.insert).join("") || "";

    const Extend = `Explain it more ${textToExtend}`;

    const payload = [
      { role: "user", content: Extend },
      {
        role: "system",
        content:
          "Response should be in " +
          (language === "en" ? "English" : "Russian"),
      },
    ];

    const response_ai = await OPENAPI(
      payload,
      max_tokens,
      temperature,
      user_token
    );

    if (response_ai === "error") {
      toast.error(
        NotificationsToasts?.error_while_connecting_api?.notification
      );
      return;
    }
    if (response_ai === "ntoken") {
      toast.error(
        NotificationsToasts?.account_limit_reached?.notification
      );
      return;
    }

    const final_response = response_ai.choices[0].message.content;

    const Words = CountWords(final_response);

    UpdateTokens(Words);

    if (final_response) {
      const updatedHtml = editorHtml.replace(textToExtend, final_response);
      setEditorHtml(updatedHtml);
    }

    setRewriteLoading(false);
  };


  const UpdateTokens = async (used) => {
    const response = await do_update_token(used, getLoggedObject()?.token ,NotificationsToasts );
    if (response) {
      dispatch(setTokens(response));
    }
  };

  return (
    <div className="layout-container container-fluid">
      <NavBarLOGIN />
      <div className="layout-row row">
        <SideBar />

        <div className="scrollable-body col-md-9 col-12">
          <div className="container-wrappe">
            {" "}
            <div className="main-layout">
              <div className="row chat-layout">
                <div className="col-md-5">
                  <section className="tool-panel chat-section">
                    <div className="chatbot-container">
                      <div className="messages-container">
                        {Loading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100vh",
                              width: "100%",
                            }}
                          >
                            <div className="loader"></div>
                          </div>
                        ) : Chats.length > 0 ? (
                          Chats.map((item, index) => (
                            <React.Fragment key={index}>
                              <div className="message-container user-message">
                                <div className="message-bubble user-message">
                                  {item?.userMessage}
                                </div>
                              </div>
                              <div className="message-container assistant-message">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.response,
                                  }}
                                  className="message-bubble assistant-message"
                                />
                                <div className="action-btns">
                                  <div className="word-character-count">
                                    {CountWords(item?.response)} Words
                                  </div>
                                  <div className="btns-wrap">
                                    <button
                                      onClick={() =>
                                        handleCopyClick(item?.response)
                                      }
                                      className="btn btn-outline"
                                    >
                                      <svg
                                        width={20}
                                        height={20}
                                        shapeRendering="geometricPrecision"
                                        textRendering="geometricPrecision"
                                        imageRendering="optimizeQuality"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        viewBox="0 0 438 511.52"
                                      >
                                        {/* Copy SVG */}
                                      </svg>{" "}
                                      <Translate>Copy</Translate>
                                    </button>
                                    <button
                                      onClick={() =>
                                        setEditorHtml(item?.response)
                                      }
                                      className="btn btn-outline"
                                    >
                                      <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 377 377"
                                        enableBackground="new 0 0 377 377;"
                                      >
                                        {/* Add to Editor SVG */}
                                      </svg>{" "}
                                      <Translate>Add to editor</Translate>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))
                        ) : (
                          <div className="smartChart">
                            <div className="chatIcon">
                              <svg
                                width={20}
                                height={20}
                                version="1.1"
                                shapeRendering="geometricPrecision"
                                textRendering="geometricPrecision"
                                imageRendering="optimizeQuality"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                viewBox="0 0 493 511.77"
                              >
                                {/* SmartChat SVG */}
                              </svg>
                            </div>
                            <h3>
                              {t("AIChat.label")}
                            </h3>
                            <p>
                              <strong>
                                {t("AIChat.featuresTextAI")}
                              </strong>{" "}
                              <br />
                                {t("AIChat.AIChatDescription")}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="user-input-wrap">
                        <div className="user-info-wrap">
                          <button
                            onClick={() => setChats([])}
                            className="btn btn-outline btnTry sub-btn"
                          >
                            {t("AIChat.newChat")}
                          </button>
                          <button
                            onClick={handleTryAgain}
                            className="btn btn-outline btnTry"
                          >
                            <svg
                              version="1.1"
                              id="Uploaded to svgrepo.com"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="14px"
                              height="14px"
                              viewBox="0 0 32 32"
                              enableBackground="new 0 0 32 32"
                            >
                              <path
                                d="M28,24v4H4V8h10.071l-3.657-3.654l2.828-2.826l8.485,8.485l-8.485,8.484l-2.828-2.83L14.071,12H8v12H28z
 "
                                fill="#3FC27E"
                              />
                            </svg>
                            {t("AIChat.tryAgain")}
                          </button>
                          <button
                            onClick={() => {
                              setChats([]);
                              setEditorHtml("");
                            }}
                            className="btn btn-outline btnTry sub-btn"
                          >
                            {t("AIChat.clearChat")}
                          </button>
                        </div>
                        <div className="user-input-container">
                          <textarea
                            rows={1}
                            id="user-input"
                            className="user-input"
                            placeholder={
                              show_lang == "en"
                                ? "Type your message..."
                                : "Введите сообщение..."
                            }
                            onChange={(e) => setQuestion(e.target.value)}
                            value={question}
                          />
                          <button
                            onClick={SubmittChat}
                            disabled={Loading}
                            className="send-button "
                          >
                            <svg
                              width={20}
                              height={20}
                              focusable="false"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="#3FC27E"
                                d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-7 mainEditor">
                  {RewriteLoading && (
                    <div className="Custom_loader">
                      <div className="loader"></div>
                    </div>
                  )}
                  <div style={{marginRight:"10px"}} className="ChatsBtns">
                    <div className="wktooltip">
                      <button
                        disabled={selectedText === "" ? true : false}
                        type="button"
                        className="ml-auto mr-auto rewrite btn btn-outline-secondary btn-sm"
                        onClick={RewriteContent}
                      >
                        <svg
                          version="1.1"
                          id="Uploaded to svgrepo.com"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="14px"
                          height="14px"
                          viewBox="0 0 32 32"
                          enableBackground="new 0 0 32 32"
                        >
                          <path
                            d="M28,24v4H4V8h10.071l-3.657-3.654l2.828-2.826l8.485,8.485l-8.485,8.484l-2.828-2.83L14.071,12H8v12H28z"
                            fill="#3fc27e"
                          />
                        </svg>{" "}
                        {/* <Translate>Rewrite</Translate> */}
                      </button>
                      <span className="wktooltiptext">
                        <Translate>Rewrite sentence</Translate>
                      </span>
                    </div>
                    <div className="wktooltip">
                      <button
                        type="button"
                        className="ml-1 mr-auto expand btn btn-outline-secondary btn-sm"
                        disabled={selectedText === "" ? true : false}
                        onClick={ExtendContent}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="16px"
                          height="16px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <g>
                              <rect transform="rotate(180 12 12)" opacity={0} />
                              <path
                                fill="#3fc27e"
                                d="M20 5a1 1 0 0 0-1-1h-5a1 1 0 0 0 0 2h2.57l-3.28 3.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L18 7.42V10a1 1 0 0 0 1 1 1 1 0 0 0 1-1z"
                              />
                              <path
                                fill="#3fc27e"
                                d="M10.71 13.29a1 1 0 0 0-1.42 0L6 16.57V14a1 1 0 0 0-1-1 1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2H7.42l3.29-3.29a1 1 0 0 0 0-1.42z"
                              />
                            </g>
                          </g>
                        </svg>{" "}
                        {/* <Translate>Extend</Translate> */}
                      </button>
                      <span className="wktooltiptext">
                        <Translate>Extend sentence</Translate>
                      </span>
                    </div>
                    <div className="wktooltip">
                      <button
                        type="button"
                        onClick={() => handleCopyClick(editorHtml , NotificationsToasts)}
                        className="ml-1 mr-auto btn btn-outline-secondary copy btn-sm"
                        disabled={editorHtml === "" ? true : false}
                      >
                        <svg
                          stroke="#3fc27e"
                          fill="#3fc27e"
                          strokeWidth={0}
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              fill="#3fc27e"
                              d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1H7zM5.002 8L5 20h10V8H5.002zM9 6h8v10h2V4H9v2zm-2 5h6v2H7v-2zm0 4h6v2H7v-2z"
                            />
                          </g>
                        </svg>{" "}
                        {/* <Translate>Copy</Translate> */}
                      </button>
                      <span className="wktooltiptext">
                        <Translate>Copy</Translate>
                      </span>
                    </div>
                    <div className="wktooltip">
                      <button
                        type="button"
                        disabled={editorHtml === "" ? true : false}
                        id={1}
                        className="ml-1 mr-auto btn btn-outline-secondary save btn-sm"
                        onClick={() =>
                          handleSaveContent(
                            editorHtml,
                            "smart_chat",
                            CountWords(editorHtml),
                            getLoggedObject()?.token,
                            NotificationsToasts
                          )
                        }
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              fill="#3fc27e"
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                            />
                          </g>
                        </svg>{" "}
                        {/* <Translate>Save</Translate> */}
                      </button>
                      <span className="wktooltiptext">
                        {" "}
                        <Translate>Save</Translate>
                      </span>
                    </div>
                  </div>
                  <div className="qTextEditor card ChatEditor">
                    <ReactQuill
                      value={editorHtml}
                      onChange={setEditorHtml}
                      onChangeSelection={handleSelectionChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                inset: 16,
                pointerEvents: "none",
              }}
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
