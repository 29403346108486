// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isAuthenticated: false,
  Language: 2,
  Tokenvalue: 0,
  NotificationsToasts: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    setTokens: (state, action) => {
      state.Tokenvalue = action.payload;
    },
    setNotificationsTostify: (state, action) => {
      state.NotificationsToasts = action.payload;
    },
  },
});

export const { setUser, clearUser, setTokens, setNotificationsTostify } =
  userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
